import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { TranslationControlIcon } from '../../../study/views/player-icons';
import { paletteKey } from '../../../study/views/study-palette';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';
import {
  TranslationTip,
  // TranslationTipDialog,
} from 'components/ui/onboarding/translation-tip';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
  },
});

const TogglerWrapper = styled('span', {
  color: 'inherit',
  variants: {
    active: {
      true: {
        color: paletteKey('$$transport__buttonColor_active'),
      },
    },
  },
});

const TranslationToggler: React.FC = observer(({ children }) => {
  const model = usePlayerModel();
  const active = model.translationsShown;
  return <TogglerWrapper active={active}>{children}</TogglerWrapper>;
});

export const TranslationButton = observer(
  ({ disabled }: { disabled?: boolean }) => {
    const model = usePlayerModel();
    const tipDismissed = OnboardingService.instance.isDismissed('translation');
    const showPopover = OnboardingService.instance.showTranslationTip;
    return (
      <>
        <OnboardingPopover
          dismissed={tipDismissed}
          showPopover={showPopover}
          renderAnchor={({ ref, getProps }) => (
            <ToolipPositioner
              ref={ref}
              {...getProps()}
              className={showPopover ? 'highlight' : null}
            >
              <ControlButton
                onClick={() => model.toggleTranslations()}
                disabled={disabled}
              >
                <TranslationToggler>
                  <TranslationControlIcon />
                </TranslationToggler>
              </ControlButton>
            </ToolipPositioner>
          )}
          renderBody={() => {
            // @armando this doesn't seem to render when 'showPoper' is true
            return <TranslationTip />;
          }}
          onResolvePopover={OnboardingService.instance.translationTipDismissed}
        />
        {/* <TranslationTipDialog /> */}
      </>
    );
  }
);
