import { LocaleCode } from '@utils/util-types';

export type PricingLevel = 'retail' | 'affiliate' | 'student';
export type PricingGeneration = '2021' | '2023' | 'dev' | 'foo' | 'foo2';
export type BillingInterval = 'month' | 'year' | 'day';
// export type BillingInterval2 = 'month' | 'year';
export type Currency = 'usd' | 'brl';
export type PlanSlug =
  `${LocaleCode}-${PricingLevel}-${PricingGeneration}-${Currency}-${BillingInterval}`;

export type StripeProductId = `${LocaleCode}-${PricingLevel}`;

export const PricingLevels: PricingLevel[] = ['retail', 'affiliate', 'student']; // as const;
export const L2s: LocaleCode[] = ['es', 'en']; // as const;

export const allCurrencies = (): Currency[] => ['usd', 'brl'];

export type StripeMetadata = {
  planSlug: string;
  l2: LocaleCode;
  pricingLevel: PricingLevel;
  pricingGeneration: PricingGeneration;
  billingInterval: BillingInterval;

  userId?: string; // only on checkout
  stripeSubscriptionId?: string; // convenient to merge in to subscriptionStarted
};

export interface NodeAccountData {
  _docId: string; // uuid
  userId: string; // server qualified
  email: string;

  stripeCustomerId?: string;
  currency?: Currency;

  // entitlements: {
  //   // eslint-disable-next-line no-unused-vars
  //   [l2 in LocaleCode]?: EntitlementState;
  // };

  subscriptions: {
    // eslint-disable-next-line no-unused-vars
    [l2 in LocaleCode]?: SubscriptionState;
  };

  // chargebeeCustomerId?: string;
  // _timestamp: string; // iso8601, beware, not existing in data prior to new backup logic
  // the rest of the user data props can remain opaque
  [index: string]: unknown;
}

// export interface EntitlementState {
//   l2: LocaleCode;
//   accessUntil: string; // iso8601 date
//   accessSince: string; // iso8601 date
//   subscriptionState?: SubscriptionState;
// }

// consider renaming this flat/fat structure to EntitlementState
export interface SubscriptionState {
  l2: LocaleCode;

  accessUntil?: string; // iso8601 date
  accessSince?: string; // iso8601 date

  planSlug?: string; //todo: restore typing once json schema gen dealt with. //PlanSlug;
  stripeSubscriptionId?: string;

  failureCode?: string;
  failureMessage?: string;

  pausedOn?: string;
  pausedUntil?: string;
  billingResumesOn?: string;

  billingInterval?: BillingInterval;
  currency?: Currency;
  price?: number;
  priceLevel?: PricingLevel;
  priceGeneration?: PricingGeneration;

  railsMigrated?: boolean;
}

export type EntitlementData = SubscriptionState;
