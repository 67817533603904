//
// not currently relevant
// pause/resume will be revisited in the future
//

import * as React from 'react';
import { observer } from 'mobx-react';

import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { MediumHeading } from 'naan/primitives/text';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { VSpacer } from 'naan/primitives/spacer';
import { formatDate } from 'utils/format-date';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { bugsnagNotify } from '@app/notification-service';

import __ from 'core/lib/localization';

export const TextButton = styled('button', {
  all: 'unset',
  color: '$colors$teal-500',
  textStyle: 'body',
  textDecoration: 'underline',
  cursor: 'pointer',
  display: 'flex',
  '& .icon': {
    color: '$red-400',
  },
});

export const PausedAccess = observer(() => {
  const { userManager } = AppFactory.root;
  const { membershipDisplay, autoRenewAmountDisplay } = userManager ?? {};

  const nodeState = userManager.nodeEntitlement;
  const { pausedUntil, billingResumesOn } = nodeState;

  return (
    <>
      <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
      <VSpacer size="4" />
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell>{membershipDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Full access resumes', 'fullAccessResumes')}
            </TableLabelCell>
            <TableValueCell>
              <HStack justify={'space'}>
                <VStack
                  css={{
                    maxWidth: '66%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {formatDate(pausedUntil)}
                </VStack>
                <Button
                  onClick={() =>
                    userManager.resumeSubscription().catch(bugsnagNotify)
                  }
                  label={__('Resume now', 'resumeNow')}
                  size={'small'}
                  presentation={'redLight'}
                  data-test-id="auto-renew-off"
                  css={{
                    margin: '-4px 0',
                  }}
                />
              </HStack>
            </TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Billing resumes', 'billingResumes')}
            </TableLabelCell>
            <TableValueCell>{formatDate(billingResumesOn)}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Renewal amount', 'renewalAmount')}
            </TableLabelCell>
            <TableValueCell>{autoRenewAmountDisplay}</TableValueCell>
          </TableRow>
          {/* <TableRow>
            <TableLabelCell>
              {__('Payment method', 'paymentMethod')}
            </TableLabelCell>
          </TableRow> */}
        </tbody>
      </Table>
      <LargeGap />
    </>
  );
});
