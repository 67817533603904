import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import { styled } from '@naan/stitches.config';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { SnailIcon } from '@naan/icons/snail-icon';
import { CopySmallIcon } from '@naan/icons/copy-icon';
import { Text } from '@naan/primitives/text';
import { StudyModel } from 'study/models/study-model';
import { AppFactory } from '@app/app-factory';
import { useCopyPowers } from '@common/hooks/use-copy-powers';
import { NotificationService } from '@app/notification-service';
import { Responsive } from '@naan/primitives/responsive';

import __ from '@core/lib/localization';

const Wrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '0 16px var(--sab)',
  '& > li': {
    display: 'flex',
    borderTop: '1px solid $colors$gray-100',
    '& > button': {
      all: 'unset',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-start',
      padding: '16px 18px',
      transition: 'all 0.12s ease',
      '&[disabled]': {
        opacity: 0.5,
      },

      '&:active': {
        backgroundColor: '$gray-20',
      },

      '& .icon': {
        marginLeft: -16,
        marginRight: 12,
        '& svg': {
          width: 24,
          height: 24,
        },
      },
      '& .label': {
        // textStyle: 'small-text-bold',
      },
    },
  },
});

const HiddenTextarea = styled('textarea', {
  position: 'absolute',
  left: -1000,
  top: -1000,
});

const SentenceModalContent = ({
  model,
  onDismiss,
}: {
  model: StudyModel;
  onDismiss: () => void;
}) => {
  // (window as any).model = model;
  const { ref, active, copyAction } = useCopyPowers();

  const handleCopy = React.useCallback(() => {
    copyAction()
      .then(() => {
        NotificationService.open({
          message: __('Copied to clipboard', 'copiedToClipboard'),
          type: 'success',
        });
      })
      .catch(() => {
        NotificationService.open({
          message: 'Failed to copy',
          type: 'warning',
        });
      })
      .finally(() => {
        onDismiss();
      });
  }, [copyAction, onDismiss]);

  return (
    <Wrapper>
      <li>
        <button
          onClick={e => {
            e.preventDefault();
            onDismiss();
            model.replayCurrentSentence();
          }}
        >
          <span className="icon">
            <ReplayIcon />
          </span>
          <span className="label">{__('Replay', 'replay')}</span>
        </button>
      </li>
      <li>
        <button
          onClick={e => {
            e.preventDefault();
            onDismiss();
            model.snailReplayCurrentSentence();
          }}
        >
          <span className="icon">
            <SnailIcon />
          </span>
          <span className="label">{__('Slow replay', 'slowReplay')}</span>
        </button>
      </li>
      <li>
        <button onClick={handleCopy} disabled={active}>
          <span className="icon">
            <CopySmallIcon />
          </span>
          <span className="label">{__('Copy', 'copy')}</span>
        </button>
        <HiddenTextarea ref={ref as any}>
          {model.currentSentenceText}
        </HiddenTextarea>
      </li>
    </Wrapper>
  );
};

const SentenceSheet = ({
  model,
  onDismiss,
}: {
  model: StudyModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container open onDismiss={onDismiss} flavor="sheet">
      <Dialog.Heading>
        <Text textStyle="small-caps" color="textSecondary">
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton />
      <SentenceModalContent model={model} onDismiss={onDismiss} />
    </Dialog.Container>
  );
};

const SentenceModal = ({
  model,
  onDismiss,
}: {
  model: StudyModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Heading>
        <Text textStyle="small-caps" color="textSecondary">
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton />
      <SentenceModalContent model={model} onDismiss={onDismiss} />
    </Dialog.Container>
  );
};

export const presentSentenceActions = (model: StudyModel) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <Responsive
      renderDefault={() => (
        <SentenceSheet onDismiss={onDismiss} model={model} />
      )}
      renderMediumAndUp={() => (
        <SentenceModal onDismiss={onDismiss} model={model} />
      )}
    />
  ));
};
