import { styled } from '@naan/stitches.config';

export const VocabListBadge = styled('button', {
  $$iconColor: '$colors$white',
  $$textColor: '$colors$textPrimary',
  color: '$$textColor',
  unset: 'all',
  position: 'relative',
  backgroundColor: '$$iconColor',
  height: 'fit-content',
  minWidth: '20px',
  margin: '10px',
  borderRadius: '12px',
  textStyle: 'small-caps',
  textAlign: 'center',
  padding: '0 5px 0 6px',
  '&::after': {
    $$size: '8px',
    position: 'absolute',
    right: '-2px',
    bottom: '-2px',
    borderTop: '$$size solid black',
    borderTopColor: 'inherit',
    borderLeft: 'calc($$size/2) solid transparent',
    borderRight: 'calc($$size/2) solid transparent',
    transform: 'rotate(-50deg)',
    content: '',
    color: '$$iconColor',
  },
});
