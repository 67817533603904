import * as React from 'react';
import * as Onboarding from './components';
import { PlainMarkdown } from '@naan/primitives/text';
import imageSrc from './smart-pause-illustration.png';

import __ from 'core/lib/localization';

export const SmartPauseTip = () => {
  return (
    <Onboarding.Container>
      {/* <Onboarding.Image src={'https://placekitten.com/200/300'} /> */}
      <Onboarding.Image src={imageSrc} width={80} height={64} />
      <Onboarding.Title>
        {__('Meet your Smart Pause button', 'smartPauseTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Listening is best done in whole sentences. When you tap pause, it plays to the end of the sentence and *then* stops. But if you tap pause again before it reaches the end, it'll stop immediately.`,
            'smartPauseTipBody'
          )}
        />
      </Onboarding.Content>
      <Onboarding.HelpletVideo videoSlug="smart-pause" />
    </Onboarding.Container>
  );
};
