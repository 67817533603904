import { observable } from 'mobx';
import { isString } from 'lodash';
import i18n from 'i18n-js';
import Dayjs from 'dayjs';

import { appConfig } from 'app/env';

// import '../../lib/services/localization-service';

import { getBus } from '@jiveworld/minibus';
import { createLogger } from '@common/log';
import { LocalizatonStore, TranslateWithDefault } from './localization-types';

import en from '../../langs/en.json';
import es from '../../langs/es.json';
import pt from '../../langs/pt.json';
import { LocaleCode } from '@utils/util-types';
// import de from '../../langs/de.json';

const log = createLogger('localization');

/**
 * This creates a custom plurailzation rule for the `ja` locale.
 * We need to support Phrase‘s implementation of japanese pluralization
 * which results on an object with at least a `other` key, and an optional
 * `zero key`
 */
// @armando: is this still relevant with the new loco flow?
i18n.pluralization['ja'] = function (count: number) {
  if (count === 0) {
    return ['zero', 'other'];
  }
  return ['other'];
};

export const systemDefaultLocale = appConfig.defaultL1;

// // non-observerable state needed to support overriding the normal locale on the account page w/o endless rerender
// // @armando, @jason can either of you thin of cleaner approach?
// let __overrideLocale: LocaleCode = undefined;

/**
 * This creates a Mobx observable store for the current locale.
 * This allows us to change the locale at runtime and have the UI react to it.
 */
function createLocalizationStore(): LocalizatonStore {
  log.debug('createLocalizationStore');

  // const translations = { en, es, de, pt };
  const translations = { en, es, pt };

  const __locale = observable.box(systemDefaultLocale);

  i18n.translations = translations;
  i18n.fallbacks = true;
  i18n.missingTranslation = () => undefined;

  // addTranslations('en', en);
  // addTranslations('es', es);
  // addTranslations('de', de);
  // addTranslations('pt', pt);
  // // addTranslations('ja', ja);

  function addTranslations(lang: string, strings = {}) {
    i18n.translations[lang] = strings;
  }

  function translateWithoutDefault(
    scope: string,
    options: i18n.TranslateOptions = {} // string substitutions
    // ...rest: any[]
  ) {
    options.locale = getLocale();
    const str = i18n.t(scope, { ...options } /* , ...rest */);
    if (appConfig.i18n.showBrackets) {
      return `[${str}]`;
    }

    if (!isString(str)) {
      // todo: consider reintroducing an 'assert' pattern which is fatal in dev
      log.error(
        `string expected: i18n(${scope}) -> ${JSON.stringify(
          str
        )}, type: ${typeof str}`
      );
      return `[${scope}]`;
    }
    return str;
  }

  function setLocale(newLocale: LocaleCode) {
    const resolvedLocale: LocaleCode = newLocale;
    __locale.set(resolvedLocale);

    /// Assumes that the Dayjs localization support has been appropriately
    /// initialized within the app level localization-service.js
    Dayjs.locale(resolvedLocale);
    getBus('localization').emit('setLocale', resolvedLocale);
  }

  // function setOverrideLocale(newLocale: LocaleCode) {
  //   __overrideLocale = newLocale;
  // }

  // function clearOverrideLocale() {
  //   __overrideLocale = undefined;
  // }

  function getLocale() {
    // return __overrideLocale || __locale.get();
    return __locale.get();
  }

  function onLocaleChange(callback: (...args: any[]) => boolean | void) {
    return getBus('localization').subscribe('setLocale', callback);
  }

  function translateWithDefault(
    defaultValue:
      | string
      | Record</* 'zero' | 'one' | 'other' */ string, string>,
    scope: string,
    options: i18n.TranslateOptions = {}
    // ...rest
  ) {
    return translateWithoutDefault(
      scope,
      { defaultValue: defaultValue as any, ...options } /*, ...rest */
    );
  }

  // defer evaluation, needed for module level initialized values
  function translateWithDefaultFn(...args: Parameters<TranslateWithDefault>) {
    return () => translateWithDefault(...args);
  }

  // in runtime all it does is returning the scope,
  // but it's picked by the get-strings parser to populate en.json
  function scopePassthrough(_: any, scope: string) {
    return scope;
  }

  function getAvailableTranslations() {
    return Object.keys(i18n.translations);
  }

  return {
    addTranslations,
    // translateWithoutDefault,
    setLocale,
    getLocale,
    // setOverrideLocale,
    // clearOverrideLocale,
    translateWithDefault,
    translateWithDefaultFn,
    translateWithoutDefault,
    scopePassthrough,
    onLocaleChange,
    getAvailableTranslations,
    disabled: false,
  };
}

export const store = createLocalizationStore();

// reexport i18n for debug purposes
export const i18nInstance = i18n;
(window as any).i18n = i18n;

// // expose a version without a default for usages isolated from the english reference parsing
// // export const t = store.translateWithoutDefault;

// export const getLocale = store.getLocale;
// export const setLocale = store.setLocale;
// export const onLocaleChange = store.onLocaleChange;

// // in runtime all it does is returning the scope,
// // but it's picked by the get-strings parser to populate en.json
// // export const __s = (_: any, scope: string) => scope;
// // export const __f =
// //   (...args: Parameters<typeof store.translateWithDefault>) =>
// //   () =>
// //     store.translateWithDefault(...args);
// export const __ = store.translateWithDefault;

// export const addTranslations = store.addTranslations;

// export default __;
