/* eslint-disable no-alert */
import React from 'react';
import { Menu, MenuItem } from 'naan/primitives/menus';
import { IconButton, Button } from 'naan/primitives/button';
import { Heading } from 'components/ds/common/heading';
import { styled } from 'naan/stitches.config';
import { FavoriteIcon } from 'naan/icons/favorite-icon';
import { FavoriteFilledIcon } from 'naan/icons/favorite-filled-icon';
import { CheckmarkIcon } from 'naan/icons/checkmark-icon';

const Wrapper = styled('div', {
  p: '$space$6',
});

const Box = styled('div', {
  display: 'flex',
  placeContent: 'center',
  padding: 20,
  margin: 20,
  background: '$teal-50',
});

const items = [
  { label: 'Item one', action: () => alert('You clicked Item one') },
  { label: 'Item two', action: () => alert('You clicked Item two') },
  {
    label: 'Item three',
    disabled: true,
    action: () => alert('This should never happen'),
  },
  {
    label: 'This requires confirmation',
    requiresConfirmation: true,
    isDestructive: true,
    action: () => {
      alert('You confirmed');
      return false;
    },
  },
];

// <MenuContainer>
//   <Menu>
//     <MenuItem
//       label={'This is great'}
//       action={() => {
//         alert('Did this work?');
//       }}
//     />
//     <MenuItem label={'No, this is dumb'} disabled />
//     <ConfirmableMenuItem
//       label={'Ruin my life'}
//       isDestructive
//       action={() => {
//         alert('You did confirm');
//       }}
//       confirmationTitle="Are you dumb?"
//       confirmationBody="This will ruin your life"
//       confirmationOkButtonLabel="Ruin it!"
//       confirmationCancelButtonLabel="Chicken out!"
//     />
//   </Menu>
// </MenuContainer>;

export const pathname = '/menus';
export const navlabel = 'Menus';
export const naanReady = true;

export const Screen = () => {
  const action = () => {
    // eslint-disable-next-line no-console
    console.log('action');
  };
  return (
    <Wrapper>
      <Heading>Menus</Heading>
      <Box>
        <Menu items={items} />
      </Box>
      <Box>
        <Menu>
          <MenuItem
            action={action}
            label="With left icon"
            leftIcon={<FavoriteFilledIcon />}
          />
          <MenuItem
            action={action}
            label="With right Icon"
            rightIcon={<CheckmarkIcon />}
          />
          <MenuItem
            action={action}
            label="With both icons"
            leftIcon={<FavoriteIcon />}
            rightIcon={<CheckmarkIcon />}
          />
          <MenuItem
            action={action}
            label="Disabled"
            disabled
            leftIcon={<FavoriteIcon />}
          />
          <MenuItem
            action={action}
            label="Dangerous"
            isDestructive
            rightIcon={<CheckmarkIcon />}
          />
        </Menu>
      </Box>
      <Box>
        <Box>
          <Menu
            items={items}
            trigger={
              <IconButton
                presentation="grayLight"
                icon={<CheckmarkIcon />}
                testId="showcase-menu"
              />
            }
          />
        </Box>
        <Box>
          <Menu
            items={items}
            trigger={<Button presentation="grayLight" label={'Hey'} />}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};
