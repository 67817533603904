import * as React from 'react';
// import * as Dialog from '@naan/primitives/modals/dialog';

import { observer } from 'mobx-react';
import { SettingsModalContainer } from './settings-modal-container';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
// import { Select } from '@naan/primitives/input/select';
import { AppFactory } from '@app/app-factory';
import { notifySuccess } from '@app/notification-service';
import { SwitchButton } from 'components/ds/switch-button';
import { HSpacer } from '@naan/primitives/spacer';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { HStack } from '@naan/primitives/stack';
import { Text } from '@naan/primitives/text';
import __, { localizationDisabled } from '@core/lib/localization';
// import { LocaleCode } from '@utils/util-types';

const Wrapper = styled('div', {
  '& > .row': {
    padding: '16px 0',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 16,
    '&:last-child': {
      paddingBottom: '8px',
    },
    '&.stack-on-small': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      '@medium': {
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto',
      },
    },
    '& + &': {
      borderTop: '1px solid $colors$gray-100',
    },
    '& > .left': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 4,
      '& > .title': {
        textStyle: 'body',
        color: '$textPrimary',
      },
      '& > .hint': {
        textStyle: 'small-text',
        color: '$textSecondary',
      },
      '& > .extra': {
        '& > a': {
          color: '$textSecondary',
          textStyle: 'small-text',
        },
      },
    },
    '& > .bottom': {
      gridColumn: 'span 2',
    },
  },
});

const ResetTipGuard = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexWrap: 'wrap',
});

// const LocaleToggle = observer(() => {
//   // const { localState } = AppFactory.root;
//   const { userData } = AppFactory.root.userManager;
//   const locale = getLocale();
//   // const locales = ['en', 'es', 'de', 'pt'];
//   // const locales = getAvailableTranslations();
//   return (
//     <Select
//       value={locale}
//       onChange={event => {
//         // setLocale(event.target.value);
//         // localState.storeLocale(event.target.value).catch(bugsnagNotify);
//         userData
//           .selectL1(event.target.value as LocaleCode)
//           .catch(bugsnagNotify);
//       }}
//     >
//       {/* todo: confirm how these labels should be presented, likely unlocalized in own language */}
//       <option value={'en'}>English</option>
//       <option value={'es'}>Español</option>
//       <option value={'pt'}>Português</option>
//       {/* <option value={'de'}>{__('German', 'german')}</option> */}
//     </Select>
//   );
// });

const ImmersiveViewSwitch = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleImmersiveView();
  return (
    <SwitchButton
      onChange={toggle}
      active={userSettings.immersiveViewEnabled}
    />
  );
});

const VocabExportSwitch = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const toggle = () => userSettings.toggleVocabExport();
  return (
    <SwitchButton
      onChange={toggle}
      active={userSettings.showVocabListExportOption}
    />
  );
});

const ResetTipsButton = observer(() => {
  const { userSettings } = AppFactory.root.userManager.userData;
  const dialogSwitch = useSwitch2(false);

  return (
    <>
      {dialogSwitch.value ? (
        <ResetTipGuard>
          <Text textStyle="small-text-bold" color="textPrimary">
            {__('Are you sure?', 'areYouSure')}
          </Text>
          <HStack>
            <Button
              label={__('Yes, reset', 'yesReset')}
              presentation="redLight"
              size={'small'}
              onClick={() => {
                userSettings.resetAllTips();
                notifySuccess(
                  __('Quickstart guide reset', 'quickstartGuideReset')
                );
                dialogSwitch.off();
              }}
            />
            <HSpacer size="2" />
            <Button
              label={__('Cancel', 'cancel')}
              presentation="grayLight"
              size={'small'}
              onClick={dialogSwitch.off}
            />
          </HStack>
        </ResetTipGuard>
      ) : (
        <Button
          label={__('Reset story and guide…', 'resetStoryAndGuide')}
          presentation={dialogSwitch.value ? 'redLight' : 'grayLight'}
          size={'small'}
          onClick={dialogSwitch.on}
        />
      )}
    </>
  );
});

export const SettingsModal = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const { authenticated } = AppFactory.root.userManager;
    const l2 = AppFactory.root.l2Localized;

    return (
      <SettingsModalContainer onDismiss={onDismiss}>
        <Wrapper>
          {localizationDisabled ? null : (
            <div className="row stack-on-small">
              <div className="left">
                {/* <div className="title">
                  {__('Buttons and labels', 'buttonsAndLabels')}
                </div>
                <div className="hint">
                  {__(
                    "Select a language for Jiveworld's user interface",
                    'selectALanguageForJiveworldsUserInterface'
                  )}
                </div> */}
                <div className="title">
                  {__('Immersive view', 'immersiveView')}
                </div>
                <div className="hint">
                  {__(
                    'Show buttons, labels etc in %{l2}',
                    'showButtonsLabelsEtcInL2',
                    { l2 }
                  )}
                </div>
              </div>
              <div className="right">
                {/* <LocaleToggle /> */}
                <ImmersiveViewSwitch />
              </div>
            </div>
          )}

          <div className="row">
            <div className="left">
              <div className="title">
                {__('Quickstart guide', 'welcomeTips')}
              </div>
              <div className="hint">
                {__(
                  'Learn the Jiveworld Method step by step with a short story.',
                  'learnTheJiveworldMethodStepByStepWithAShortStory'
                )}
              </div>
            </div>
            {/* <div className="right">
              <SwitchButton
                active={userSettings.welcomeTipsEnabled}
                onChange={() => userSettings.toggleWelcomeTips()}
              />
            </div> */}
            <div className="bottom">
              <ResetTipsButton />
            </div>
          </div>

          {authenticated ? (
            <div className="row">
              <div className="left">
                <div className="title">
                  {__('Vocabulary export', 'vocabularyExport')}&nbsp;
                  {__('(Experimental)', 'parenthesisExperimental')}
                </div>
                <div className="hint">
                  {__(
                    'Exposes an option on story vocabulary list that emails a data file for importing into a flashcard program',
                    'exposesAnOptionOnStoryVocabularyList'
                  )}
                </div>
                <div className="extra">
                  <a href={'http://go.jw.app/about-vocab-export'}>
                    {__('Learn more', 'learnMore')}
                  </a>
                </div>
              </div>
              <div className="right">
                <VocabExportSwitch />
              </div>
            </div>
          ) : null}
        </Wrapper>
      </SettingsModalContainer>
    );
  }
);

export const presentSettingsModal = () => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <SettingsModal onDismiss={onDismiss} />
  ));
};
