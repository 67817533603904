import { Channel } from '@core/models/story-manager/channel';
import { styled } from '@naan/stitches.config';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ChannelAvatar } from './channel-avatar';
import { observer } from 'mobx-react';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 12,
  marginTop: 12,
  marginBottom: 20,

  '& > .avatar': {
    padding: '4px 0',
  },
  '& > .text': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4,
    '& > .title-tagline': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > .title': {
        textStyle: 'medium-heading',
        color: '$black',
      },
      '& > .tagline': {
        textStyle: 'small-text',
        color: '$black-alpha-80',
      },
    },
    '& > .links': {
      textStyle: 'body-bold',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
    },
    '@medium': {
      flexDirection: 'row',
      '& > .title-tagline': {
        flex: 1,
      },
    },
  },
});

export const SectionHeadingLink = styled(Link, {
  textStyle: 'body-bold',
  textDecoration: 'none',
  color: '$teal-500',
});

// needs to be responsive so title and tagline update when locale changed
export const ChannelSectionHeading = observer(
  ({
    channel,
    renderLinks,
  }: {
    channel: Channel;
    renderLinks?: () => React.ReactNode;
  }) => {
    return (
      <Wrapper>
        <div className="avatar">
          <ChannelAvatar channel={channel} size="small" />
        </div>
        <div className="text">
          <div className="title-tagline">
            <h3 className="title">{channel.title}</h3>
            <div className="tagline">{channel.tagline}</div>
          </div>
          <div className="links">{renderLinks()}</div>
        </div>
      </Wrapper>
    );
  }
);
