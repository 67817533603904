import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
// import { ChapterButtons } from '../chapter-buttons';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
// import { AppFactory } from '@app/app-factory';
// import { maybePresentEndOfStoryCtaDialog } from 'components/cta/end-of-story-cta-dialog';
import { ellipsis } from '@utils/string-utils';
import { styled } from '@naan/stitches.config';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { Box } from '@naan/primitives/box';
import { ChapterStudyCard } from './chapter-item-contents/chapter-study-card';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { useChapterRowUiContext } from './chapter-item-contents/chapter-row-ui-model';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { SkipSoundbitesTip } from 'components/ui/onboarding/skip-soundbites-tip';
import classNames from 'classnames';

import __ from 'core/lib/localization';
import { CircleIcon } from './components/circle-icon';

const Accesory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginRight: -8,
});

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: 12,
  },
});

export const OverflowMenu = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    // const { fullAccess } = AppFactory.root.userManager;
    return (
      <Menu
        trigger={
          <IconButton
            presentation="grayTransparent"
            icon={<OverflowIcon />}
            testId={`chapter-list-overflow-${chapter.sortingRef}`}
          />
        }
      >
        {chapter.isStarted ? (
          <MenuItem
            label={__('Restart chapter', 'restartChapter') + ellipsis}
            action={
              () =>
                chapter.restartChapter() /* todo: open confirmation dialog */
            }
          />
        ) : null}
        {chapter.isCompleted ? null : (
          <MenuItem
            label={__('Mark chapter complete', 'markChapterComplete')}
            action={() => {
              chapter.markComplete();
              // maybePresentEndOfStoryCtaDialog();
            }}
          />
        )}
      </Menu>
    );
  }
);

export const CurrentAndFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const anySoundbites = chapter.hasSoundbites;
    const model = useChapterRowUiContext();
    React.useEffect(() => {
      if (!anySoundbites && model.currentStage === 'soundbites') {
        model.setCurrentStage('study');
      }
    }, [anySoundbites, model]);

    const dismissed = OnboardingService.instance.isDismissed(
      'skipSoundbitesToChapter'
    );
    const showPopover =
      OnboardingService.instance.showSkipSoundbitesToChapterTip;

    return (
      <Box css={{ paddingBottom: 16, overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation={chapter.unstarted ? 'unvisited' : 'current'}
          stationIcon={<CircleIcon presentation="teal" />}
          accessory={
            <Accesory>
              <OverflowMenu chapter={chapter} />
            </Accesory>
          }
        />
        <ChapterItemContents
          className={classNames({ 'is-last': chapter.isLastOne })}
        >
          <ChapterSoundbites chapter={chapter} />
          <OnboardingPopover
            dismissed={dismissed}
            showPopover={showPopover}
            renderAnchor={({ ref, getProps }) => (
              <ToolipPositioner
                ref={ref}
                {...getProps()}
                className={showPopover ? 'highlight' : null}
              >
                <ChapterStudyCard chapter={chapter} />
              </ToolipPositioner>
            )}
            renderBody={() => {
              return <SkipSoundbitesTip />;
            }}
            onResolvePopover={
              OnboardingService.instance.onSoundbitesSkipDismissed
            }
          />
        </ChapterItemContents>
      </Box>
    );
  }
);
