import * as React from 'react';
import { observer } from 'mobx-react';
import { CloseControl } from './controls/close-control';
import { HeaderContainer } from './study-layout';
import { lineScrollObserver } from './fx/scrolling';
import { ModeToggler } from './controls/mode-toggler';
import { GlobalRedactionButton } from './global-redaction-button';
import { VocabListStoryButton } from 'components/story-status/vocab-list-button';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { AppFactory } from '@app/app-factory';
import { styled } from '@stitches/react';
import { VocabListTip } from 'components/ui/onboarding/vocab-list-tip';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { NaturalListenTip } from 'components/ui/onboarding/natural-listen-tip';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';

const StudyGlobalRedactionHeader = observer(() => {
  if (lineScrollObserver.isGlobalRedactionButtonVisible === true) {
    return null;
  }

  return <GlobalRedactionButton presentation="whiteTransparent" responsive />;
});

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
  },
});

const RightWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 8,
});
const ModeTogglerWrapper = styled('div', {
  position: 'absolute',
  top: 8,
  left: '50%',
  transform: 'translateX(-50%)',
  '&.highlight > *': {
    outline: '6px solid $teal-500',
    borderRadius: 12,
  },
});

/// this was separated so it can be an observable
export const VocabButtonContainer = observer(() => {
  const model = AppFactory.studyModel;
  const story = model?.chapter?.story;
  const dismissed = OnboardingService.instance.isDismissed('vocabList');
  const showPopover = OnboardingService.instance.showVocabListTip;
  const fluentListenMode = model?.fluentListenMode;

  if (fluentListenMode === true) {
    return null;
  }

  return (
    <CurrentStoryProvider story={story}>
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            <VocabListStoryButton story={story} textColor={'$blue-500'} />
          </ToolipPositioner>
        )}
        renderBody={() => {
          return <VocabListTip />;
        }}
        onResolvePopover={OnboardingService.instance.vocabListTipDismissed}
      />
    </CurrentStoryProvider>
  );
});

const ID = 'STUDY_HEADER_CONTAINER';

const headerElement = () => document.getElementById(ID);

// @armando, what the React.memo important here? it's not clear how that
// would interplay with the mobx oberserver which is needed to dismiss the popover
// export const StudyHeader = React.memo(() => {
export const StudyHeader = observer(() => {
  const popoverDismissed =
    OnboardingService.instance.isDismissed('naturalListen');
  const showPopover = OnboardingService.instance.showNaturalListenTip;

  const model = AppFactory.studyModel;

  useReaction(
    () => model.isPlaying,
    () => {
      toggleClassname(headerElement(), 'playing', model.isPlaying);
    }
  );

  return (
    <HeaderContainer id={ID}>
      <div className="left">
        <CloseControl />
      </div>
      {model.enableModeToggle ? (
        <OnboardingPopover
          showPopover={showPopover}
          dismissed={popoverDismissed}
          onResolvePopover={
            OnboardingService.instance.naturalListenTipDismissed
          }
          renderAnchor={({ ref, getProps }) => (
            <ModeTogglerWrapper
              ref={ref}
              {...getProps()}
              data-id="popover-wrapper"
              className={showPopover ? 'highlight' : null}
            >
              <ModeToggler />
            </ModeTogglerWrapper>
          )}
          renderBody={() => {
            return <NaturalListenTip />;
          }}
        />
      ) : null}

      <div className="right">
        <RightWrapper>
          <VocabButtonContainer />
          <StudyGlobalRedactionHeader />
        </RightWrapper>
      </div>
    </HeaderContainer>
  );
});
