import * as React from 'react';
import * as Interstitial from './onboarding-interstitial';
// import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { PlainMarkdown } from '@naan/primitives/text';
import { Image } from '@naan/primitives/image';
import { styled } from '@naan/stitches.config';
import { track } from '@app/track';

import __ from '@core/lib/localization';

const Container = styled('div', {
  $$backgroundColor: 'red',
  backgroundRepeat: 'no-repeat',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '$$backgroundColor',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    overflow: 'hidden',
    // height: '100%',
    background: '$$backgroundImage center/cover',
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
    opacity: 0.1,
  },
});

const ScrollWrapper = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const ContentWrapper = styled('div', {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '64px 0 28px',
  '@medium': {
    justifyContent: 'center',
    padding: '32px 0 40px',
  },
});

const StoryDetailsWrapper = styled('div', {
  $$thumbnailSize: '176px',
  $$backgroundImage: '',
  $$backgroundColor: '$blue-800',
  width: 'min(100% - 48px, 440px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 24,
  // flex: 1,
  // background: '$$backgroundColor',

  '& > .thumbnail-image': {
    width: '$$thumbnailSize',
    borderRadius: 12,
    marginBottom: 12,
    aspectRatio: '1',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
  },
  '& > .title': {
    display: 'block',
    textStyle: 'medium-heading',
    color: '$white',
    marginBottom: 32,
    '@medium': {
      marginBottom: 40,
      textStyle: 'large-heading',
    },
  },

  '@medium': {
    $$thumbnailSize: '240px',
  },
});

const StoryCopyWrapper = styled('div', {
  color: '$white',
  '& .meta': {
    paddingBottom: 12,
    borderBottom: '1px solid $white-alpha-20',
    marginBottom: 12,
  },
  '& .description': {},
});

const ButtonWrapper = styled('div', {
  width: 'min(100% - 48px, 440px)',
  marginTop: 'auto',
  '@medium': {
    marginTop: 0,
  },
});

export const StoryInterstitialInitial = observer(
  ({ story, onButtonClick }: { story: Story; onButtonClick: () => void }) => {
    // const navigate = useNavigate();
    const { themeColor } = story;

    React.useEffect(() => {
      track('onboarding__interstitial_shown', { stage: 'story' });
    }, []);

    return (
      <Container
        className="container"
        css={{
          $$backgroundColor: themeColor,
          $$backgroundImage: `url(${story.listImageUrl})`,
        }}
      >
        <Interstitial.CloseButton />
        <ScrollWrapper>
          <ContentWrapper>
            <StoryDetailsWrapper className="details-wrapper">
              <Image src={story.listImageUrl} className="thumbnail-image" />
              <div className="title">{story.title}</div>
              <StoryCopyWrapper>
                <div className="meta">
                  {/* we are in spa-9.0, there are no channels */}
                  {story.durationDescription} • Radio Ambulante
                </div>
                <div className="description">
                  <PlainMarkdown source={story.description} />
                </div>
              </StoryCopyWrapper>
            </StoryDetailsWrapper>
            <ButtonWrapper>
              <Interstitial.Button
                presentation="teal"
                label={__('Begin story', 'beginStory')}
                onClick={onButtonClick}
              />
            </ButtonWrapper>
          </ContentWrapper>
        </ScrollWrapper>
      </Container>
    );
  }
);
