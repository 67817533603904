import { styled } from 'naan/stitches.config';

export const Table = styled('table', {
  width: '100%',
  tableLayout: 'fixed',
  marginBottom: '-1px',
  borderCollapse: 'collapse',
});

export const TableRow = styled('tr', {
  $$borderColor: '$colors$gray-100',
  borderTop: '1px solid $$borderColor',
  // ${cond('noBorder', 'transparent', getColor('blackHairline'))};
  '&:last-child': {
    borderBottom: '1px solid #e5e5e5',
  },
  variants: {
    noBorder: {
      true: {
        $$borderColor: '$colors$transparent',
      },
    },
  },
});

export const TableCell = styled('td', {
  textStyle: 'body',
  padding: '16px 0',
  verticalAlign: 'top',
  position: 'relative',
});

export const TableLabelCell = styled(TableCell, {
  color: '$colors$textSecondary',
  width: '33.33333%',
  paddingRight: '1rem',
});

export const TableValueCell = styled(TableCell, {
  width: '66.66667%',
  color: 'inherit',
  variants: {
    error: {
      true: {
        color: '#e55c58',
      },
    },
  },
});
