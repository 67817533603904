import * as React from 'react';
import { SoundbiteUI } from './soundbite-ui';
import { ExcerptData } from '@tikka/client/catalog-types';
import { AppFactory } from 'app/app-factory';
import { LoadingStatus } from '../../player/models/player-model';
import { createLogger } from 'app/logger';
import { reaction } from 'mobx';
import { FullScreenLoader } from 'components/ds/modals';
import { Navigate } from 'react-router-dom';
import {
  // alertSevereError,
  alertWarning,
  bugsnagNotify,
  sanitizeErrorMessage,
} from '@app/notification-service';
import { homePath } from 'components/nav/path-helpers';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { isNetworkError } from '@core/lib/error-handling';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import __ from '@core/lib/localization';

const log = createLogger('soundbite-view');

export const updateSettings = () => {
  const model = AppFactory.soundbiteModel;

  const { userManager } = AppFactory.root;
  const { playerSettings } = userManager.userData;

  const newSettings = {
    playbackRate: model.player.playbackRate,
    redactionMode: model.redactionMode,
    introShown: playerSettings.introShown, // confirm best way to leave this untouched
  };

  if (playerSettings.isDifferent(newSettings)) {
    log.info('updating playerSettings');
    playerSettings.setAll(newSettings);
    userManager.persistUserData().catch(bugsnagNotify);
  } else {
    log.info('playerSettings unchanged');
  }
};

const loadExcerptData = async (url: string) => {
  log.info(`loadExcerptData - url: ${url}`);

  // const response = await fetch(url);
  const response = await AppFactory.assetCacher.maybeCachedResponse(url);

  const data = (await response.json()) as ExcerptData;
  return data;
};

// // todo: not sure if needs to be an observer
// export const SoundbiteView = observer(
//   ({ soundbite }: { soundbite: Soundbite }) => {
//     const { dataUrl } = soundbite;
//     log.info(`slug: ${soundbite.slug}, url: ${dataUrl}`);

interface LoaderState {
  model?: SoundbiteModel;
  failure?: Error;
}

export const SoundbiteView = ({ dataUrl }: { dataUrl: string }) => {
  log.info(`dataUrl: ${dataUrl}`);

  const { root } = AppFactory;

  // const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
  // follow the spirit of the useSWR state flow without the cache
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { model, failure } = loaderState;

  // need to make sure the useEffect is reexecuted if the singleton playerModel
  // is changed (by visiting another player screen)

  // eslint-disable-next-line
  React.useEffect(() => {
    // setDataLoaded(false);
    // const model = AppFactory.playerModelHandle.newSoundbiteModel();
    const model = new SoundbiteModel();
    model.setStatus(LoadingStatus.LOADING);
    loadExcerptData(dataUrl)
      .then(data => model.initFromData(data))
      .then(() => {
        // TODO: move to parent screens
        // track('soundbite__player_opened', {
        //   soundbiteSlug: slug,
        //   storySlug: model?.story?.slug,
        //   url,
        // }); // redundant with generic page tracking, but looks easier to explore event data vs page view date
        // todo: better factor this
        const { playerSettings } = AppFactory.root.userManager.userData;
        model.player.setPlaybackRate(playerSettings.playbackRate);
        model.setRedactionMode(playerSettings.redactionMode);
        model.setOnloadModalNeeded(!playerSettings.introShown);

        model.setReady(); // shouldn't be needed now

        // not currently relevant (was hook to trigger delayed mailing list prompt)
        root.userManager.handlePlayerVisited();

        // setDataLoaded(true);
        setLoaderState({ model });

        // automatically persist settings if changed when end reached
        // important to not setup the reaction under after the model is initialized
        reaction(
          () => model.afterNotionalCompletion,
          atEnd => {
            log.debug(`notional completion reaction - atEnd: ${atEnd}`);
            if (atEnd) {
              updateSettings();
            }
          }
        );
      })
      .catch(error => {
        // alertSevereError({ error, note: 'soundbite-view - useEffect' });
        model.loadingStatus = LoadingStatus.ERROR; // likely not needed now
        // setDataLoaded(true); // trigger rerender redirect below will execute
        setLoaderState({ failure: error });
      });

    return () => {
      if (model?.story && model.player) {
        model.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUrl]);

  // const model = AppFactory.soundbiteModel;

  if (failure) {
    // beware, this code basically duplicated from study-screen.tsx, should better factor
    const error = failure instanceof Error ? failure : Error(String(failure));

    log.error(`prepareModel failed: ${error?.stack}`);
    let message = sanitizeErrorMessage(error);
    if (!isNetworkError(error)) {
      bugsnagNotify(error);
    } else {
      message = __(
        'You appear to be offline. Please try again later.',
        'offlineTryLater'
      );
    }
    presentSimpleAlert(<>{message}</> /*, onErrorAlertDismiss*/);

    // used to happen if this screen is reached when the currently loaded catalog doesn't
    // have soundbite data for the referenced slug or current date
    // (not sure if/when can still happen)
    return <Navigate to={homePath()} replace />;
  }

  if (model === undefined) {
    return <FullScreenLoader />;
  }

  // todo: should never expose this flow. should either error or adapt
  if (!model?.story) {
    // return <h2>missing story data</h2>;
    alertWarning('missing story data');
  }
  // relies upon the singleton AppFactory.playerModel
  return <SoundbiteUI model={model} />;
};
