import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { classroomsPath, profileHomePath } from './nav/path-helpers';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';
import { openUrl } from '@naan/primitives/text/external-link';
import { appConfig } from '@app/config';
import { Message } from '@naan/global-messages';
import { embeddedAndroid, updateStoreUrl } from '@core/lib/app-util';

/**
 * This is a temporary hack to create the ONLY global message we currently have.
 */

export function useGlobalMessages() {
  const navigate = useNavigate();
  const { userManager, localState, shouldUpdateNative } = AppFactory.root;

  const { accountData, accessExpired } = userManager;
  const { showClassroomLicenseNag, paymentData } = accountData;

  const {
    needsBrownListedBrowserWarning,
    needsIosUnsupportedWarning,
    needsIosUpgradeWarning,
  } = localState;

  const currentMessage = React.useMemo<Message>(() => {
    if (paymentData?.autoRenewFailed) {
      // assign then return for better typing
      const result: Message = {
        type: 'warning',
        message: __(
          'Your card payment could not be charged',
          'yourCardPaymentCouldNotBeCharged'
        ),
        action: {
          label: __('My account', 'myAccount'),
          callback: () => navigate(profileHomePath()),
        },
        elementId: 'auto-renew-failed-message',
      };
      return result;
    }

    if (showClassroomLicenseNag) {
      const result: Message = {
        type: 'error',
        message: __(
          'A classroom has exceeded its license quota.',
          'aClassroomHasExceeded'
        ),
        action: {
          label: __('My classes', 'myClasses'),
          callback: () => navigate(classroomsPath()),
        },
        elementId: 'classroom-license-nag-message',
      };
      return result;
    }

    if (shouldUpdateNative) {
      const label = embeddedAndroid()
        ? __('Go to Play Store', 'gotoPlayStore')
        : __('Go to App Store', 'gotoAppStore');

      const result: Message = {
        type: 'warning',
        message: __('Update available', 'updateAvailable'),
        action: {
          label,
          callback: () => openUrl(updateStoreUrl()),
        },
        dismissible: false,
        elementId: 'update-available-message',
      };
      return result;
    }

    if (accessExpired) {
      const result: Message = {
        type: 'info',
        message: __('Your full access has expired', 'yourFullAccessHasExpired'),
        // action: {
        //   label: __('My account', 'myAccount'),
        //   callback: () => navigate(profileHomePath()),
        // elementId: 'access-expired',
        // },
      };
      return result;
    }

    if (needsIosUpgradeWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'For the best experience, update this device to iOS 16.4 or higher in the Settings app.',
          'forTheBestExperienceUpdateThisDeviceToIos164'
        ),
        // action: {
        //   label: __('View', 'view'),
        //   callback: () => openUrl(appConfig.website.urls.browserSupport),
        // },
        dismissible: true,
        onDismiss: () => localState.dismissUpgradeOsWarning(),
        elementId: 'upgrade-os-message',
      };
      return result;
    }

    if (needsIosUnsupportedWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'Unsupported iOS version. Please check if an iOS update is available in the Settings app.',
          'unsupportedIosVersionPleaseCheckIfAnIosUpdate'
        ),
        elementId: 'unsupported-ios-message',
        // action: {
        //   label: __('View', 'view'),
        //   callback: () => openUrl(appConfig.website.urls.browserSupport),
        // },
      };
      return result;
    }

    if (needsBrownListedBrowserWarning) {
      const result: Message = {
        type: 'warning',
        message: __(
          'See the recommended web browsers for Jiveworld',
          'brownListedWarning'
        ),
        action: {
          label: __('View', 'view'),
          callback: () => openUrl(appConfig.website.urls.browserSupport),
        },
        dismissible: true,
        onDismiss: () => localState.dismissBrownListedBrowserWarning(),
        elementId: 'brown-listed-browser-message',
      };
      return result;
    }

    return undefined;
  }, [
    paymentData?.autoRenewFailed,
    showClassroomLicenseNag,
    shouldUpdateNative,
    accessExpired,
    needsIosUpgradeWarning,
    needsIosUnsupportedWarning,
    needsBrownListedBrowserWarning,
    navigate,
    localState,
  ]);

  return currentMessage;
}
