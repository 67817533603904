import * as React from 'react';
import __ from 'core/lib/localization';
import { PlainMarkdown } from '@naan/primitives/text';
import * as Onboarding from './components';

export const NaturalListenTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__(`You're now in Natural Listen mode`, 'naturalListenTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            'If you need to look something up in Study mode, tap this switch.',
            'naturalListenTipBody'
          )}
        />
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
