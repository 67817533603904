import React from 'react';
import { observer } from 'mobx-react';
import { MediumHeading } from 'naan/primitives/text';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { VSpacer } from 'naan/primitives/spacer';
import __ from 'core/lib/localization';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';

export const FullNoRenew = observer(() => {
  // we no longer show pricing cards while access is still active
  // const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = AppFactory.root;
  const { membershipDisplay, fullAccessUntil, recurringBillingDisplay } =
    userManager;

  if (userManager.purchaseFlowDisabled) {
    return null;
  }

  return (
    <>
      <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
      <VSpacer size="4" />
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipType')}
            </TableLabelCell>
            <TableValueCell>{membershipDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Recurring billing', 'recurringBilling')}
            </TableLabelCell>
            <TableValueCell>{recurringBillingDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>{__('Expires', 'expires')}</TableLabelCell>
            <TableValueCell>
              {formatDate(fullAccessUntil)}
              {/* {!userManager.purchaseFlowDisabled &&
              accountData?.shouldPromoteRenewal ? null : (
                <BlueLink onClick={prevent(changingSwitch.toggle) as any}>
                  {changing ? __('Cancel', 'cancel') : __('Extend', 'extend')}
                </BlueLink>
              )} */}
            </TableValueCell>
          </TableRow>
          {/* {changing ? (
            <TableRow noBorder={true}>
              <td colSpan={2}>
                <VStack>
                  <VSpacer size={10} />
                  <PricingCards />
                  <VSpacer size={10} />
                </VStack>
              </td>
            </TableRow>
          ) : null} */}
        </tbody>
      </Table>
      <LargeGap />
    </>
  );
});
