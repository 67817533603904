import deepmerge from 'deepmerge';
import { config as baseConfig } from './base-devtest';

//
// en.test.jiveworld.app
//
// nps deploy.testEn
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'beta',
  caliServerEnv: 'beta',

  piMenuEnabled: false,
  inviteGateEnabled: true,

  // showUnhandledErrors: true,

  // authShortCodeEnabled: false,

  analytics: {
    // mixpanelNode: {
    //   enabled: false, // exploratorily turn off to evaluate impact to startup dynamics
    // },
    googleAnalyticsKey: 'G-PSM9E3F4ZF',
  },

  website: {
    // urls: {
    //   marketingSite: 'https://www.staging.jiveworld.app',
    // },
    // allows sharing of jw-traffic-source data to rails api
    cookieDomain: '.test.jiveworld.app',
  },

  lambdaFunctions: {
    enabled: true,
  },

  // defaultL1: 'pt',
  // forcedL2: 'en',
  // catalogs: {
  //   en: {
  //     pt: 'english-pt', // override for now so test can be a preview of the brasil deployment catalog
  //   },
  // },
});
