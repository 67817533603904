import * as React from 'react';
import useSWR from 'swr';
import { styled } from 'naan/stitches.config';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { devMode } from 'app/platform';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { appConfig } from '@app/env';
import { UserManager } from '@core/models/user-manager';
import { agePretty } from '@core/lib/pretty-duration';
import { Definition } from './components/definition';
import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@common/log';
import { TipKey } from '@app/onboarding/onboarding-service';

const log = createLogger('dev-data-dump');

// import { MergeProgressButton } from 'components/account/merge-progress-button';

// const fetchTraffiSource = async (dummy: string): Promise<object> => {
//   return await AppFactory.root.userManager.getTrafficSourceCookie();
// };

const DismissedMessages = observer(() => {
  const { userData, persistUserData } = AppFactory.root.userManager;
  const { userSettings } = userData;
  const { dismissedMessageSet } = userSettings;

  const resetMessage = React.useCallback(
    (messageKey: string) => {
      userSettings.resetMessage(messageKey);
      persistUserData().catch(bugsnagNotify);
    },
    [persistUserData, userSettings]
  );

  // const messages = Array.from(dismissedMessageSet.entries());
  const messages = Array.from(dismissedMessageSet.entries()).filter(
    ([, v]) => v !== false
  );

  return (
    <>
      {messages.length === 0 ? <p>No dismissed messages</p> : null}
      {messages.map(([messageKey, value]) => {
        if (value === false) {
          return null;
        }
        return (
          <HStack
            key={messageKey}
            css={{
              gap: 8,
              borderBottom: '1px solid $colors$gray-50',
              padding: 8,
            }}
          >
            <code>{messageKey}</code>
            <ActionLink onPress={() => resetMessage(messageKey)}>
              [ &times; reset ]
            </ActionLink>
          </HStack>
        );
      })}
    </>
  );
});

// todo: factor with DismissedMessages
const DismissedTips = observer(() => {
  const { userData, persistUserData } = AppFactory.root.userManager;
  const { userSettings } = userData;
  const { dismissedTipSet } = userSettings;

  const resetKey = React.useCallback(
    (key: TipKey) => {
      userSettings.resetTip(key);
      persistUserData().catch(bugsnagNotify);
    },
    [persistUserData, userSettings]
  );

  // const messages = Array.from(dismissedMessageSet.entries());
  const keys = Array.from(dismissedTipSet.entries()).filter(
    ([, v]) => v !== false
  );

  return (
    <>
      {keys.length === 0 ? <p>No dismissed tips</p> : null}
      {keys.map(([key, value]) => {
        if (value === false) {
          return null;
        }
        return (
          <HStack
            key={key}
            css={{
              gap: 8,
              borderBottom: '1px solid $colors$gray-50',
              padding: 8,
            }}
          >
            <code>{key}</code>
            <ActionLink onPress={() => resetKey(key as TipKey)}>
              [ &times; reset ]
            </ActionLink>
          </HStack>
        );
      })}
      <ActionLink onPress={async () => userSettings.resetAllTips()}>
        [ reset all tips ]
      </ActionLink>
    </>
  );
});

export const ConfigArea = styled('textarea', {
  fontFamily: 'monospace',
  background: '$gray-50',
  padding: '$space$2',
  width: '100%',
  borderRadius: '$space$2',
});

export const path = 'data-dump';
export const label = 'Data dump';
export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager, localState } = root;
  const { accountData, nodeAccountData, userData } = userManager;

  // let { data: trafficSourceData, error: trafficSourceError } = useSWR(
  //   'dummy',
  //   fetchTraffiSource
  // );
  const trafficSourceData = userManager.getTrafficSourceCookie();

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <p>
        {accountData.email}, {accountData.userId}
      </p>
      <p>
        <ActionLink onPress={() => userManager.resetAffiliateCode()}>
          [Reset affiliate code]
        </ActionLink>{' '}
        resolved code: {String(userManager.resolveAffiliateCode())};
        jw-traffic-source: {JSON.stringify(trafficSourceData)}
        {/* {!!trafficSourceError ? (
          <>(error: ${String(trafficSourceError)}</>
        ) : null} */}
        <br />
        <ActionLink onPress={() => localState.resetInviteCode()}>
          [Reset invite code]
        </ActionLink>
        <br />
        <ActionLink
          onPress={() => userManager.userData.resetSoundbiteCompletions()}
        >
          [Reset all soundbite completion data]
        </ActionLink>
        <br />
        <ActionLink onPress={() => userManager.syncUserData()}>
          [Sync user data]
        </ActionLink>
        <br />
        <ActionLink onPress={() => userManager.loadUserData()}>
          [Load user data]
        </ActionLink>
        <br />
        <ActionLink onPress={() => userManager.storeUserData()}>
          [Store user data]
        </ActionLink>
        <br />
      </p>
      <BackupManagement />
      <ProgressInfo />
      <VSpacer size={4} />
      <p>
        {accountData.email}
        <br />
        User token: {userManager.token}
        <br />
        UserData uuid: {accountData.userDataUuid}
      </p>
      <Definition
        label={'userManager - other'}
        value={
          <ConfigArea
            cols={30}
            rows={7}
            value={JSON.stringify(userManagerOtherData, null, 2)}
            readOnly
          />
        }
      />
      <ActionLink onPress={() => userManager.refreshAccountData()}>
        [Refresh account data]
      </ActionLink>
      <Definition
        label={'accountData'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(JSON.parse(accountData.stringify), null, 2)}
            readOnly
          />
        }
      />
      <Definition
        label={'nodeAccountData'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(nodeAccountData, null, 2)}
            readOnly
          />
        }
      />{' '}
      <Definition
        label={'userData'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(JSON.parse(userData.stringify), null, 2)}
            readOnly
          />
        }
      />
      <VSpacer size={2} />
      <ActionLink
        onPress={async () => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Are you sure?')) {
            await userData.resetAllData();
          }
        }}
      >
        [Reset ALL UserData]
      </ActionLink>
      <VSpacer size={10} />
      <Definition label={'Dismissed messages'} value={<DismissedMessages />} />
      <Definition
        label={'Dismissed onboarding tips'}
        value={<DismissedTips />}
      />
      {/* <HStack gap={'small'}>
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              await userManager.nukeFirestoreUserData();
            }
          }}
        >
          [Nuke Firestore UserData]
        </ActionLink>
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              const token = userManager.token;
              await userManager.nukeFirestoreUserData();
              await userManager.applyAuthentication(token);
            }
          }}
        >
          [Nuke and re-auth]
        </ActionLink>{' '}
        &nbsp;(useful for retesting automatic data migration)
      </HStack> */}
      <Definition
        label={'localState'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(JSON.parse(localState.stringify), null, 2)}
            readOnly
          />
        }
      />
      <VSpacer size={2} />
      <ActionLink
        onPress={async () => {
          await localState.resetAllDismissed();
        }}
      >
        [Reset LocalState dismissed messages]
      </ActionLink>
      {/* {' - '}
      <ActionLink onPress={() => userManager.importUserData({ merge: true })}>
        [Merge in listening progress]
      </ActionLink>
      {' - '}
      <ActionLink onPress={() => userManager.importUserData({ merge: false })}>
        [Destructively import]
      </ActionLink>{' '}

      <VSpacer size={2} />
      {appConfig.showImportAction && userData.destructiveImportPerformed ? (
        <ActionLink onPress={() => userData.updateImportPerformed(false)}>
          [Reset 'destructiveImportPerformed' flag which influences the account
          page merge button label]
        </ActionLink>
      ) : null} */}
      {/* <MergeProgressButton />
      (from: {appConfig.importApiEnv} / {accountData.email}) */}
      <hr />
      {/* <HStack gap="small">
        testingScratch: {String(userData.testingScratch)} &nbsp;
        <ActionLink
          onPress={async () =>
            userData.testLoopedFirestorePersists({ count: 10, sleepMs: 100 })
          }
        >
          [Test looped firestore persists]
        </ActionLink>
      </HStack> */}
      <VSpacer size={4} />
      <h4>Deployment info</h4>
      <ConfigInfo />
    </VStack>
  );
});

const listBackups = async (
  /*limit: number,*/ dirty: number
): Promise<string[]> => {
  log.info(`executing listBackups`);
  const limit = 10;
  const data = await AppFactory.root.userManager.listBackups(limit);
  return data.timestamps;
};

const BackupManagement = observer(() => {
  const { userManager } = AppFactory.root;

  const [dirty, setDirty] = React.useState(Date.now());
  const markDirty = () => setDirty(Date.now());
  log.info(`BackupManagement dirty: ${dirty}`);

  return (
    <VStack>
      <br />
      <h4>Backup management</h4>
      needed: {String(userManager.userData.backupNeeded)}
      <br />
      <ActionLink
        onPress={async () => {
          await userManager.backupCurrentData();
          markDirty();
        }}
      >
        [Backup current]
      </ActionLink>
      <ActionLink
        onPress={async () => {
          await userManager.backupPriorData();
          markDirty();
        }}
      >
        [Backup prior data]
      </ActionLink>
      <h5>Available backups</h5>
      <BackupSelection dirty={dirty} markDirty={markDirty} />
      <ActionLink
        onPress={async () => {
          await userManager.mergeInAllBackups();
          markDirty();
        }}
      >
        [Merge in all backup data]
      </ActionLink>
      <br />
    </VStack>
  );
});

const BackupSelection = ({
  dirty,
  markDirty,
}: {
  dirty: number;
  markDirty: () => void;
}) => {
  const { userManager } = AppFactory.root;

  log.info(`BackupSelection dirty: ${dirty}`);

  const { data, error } = useSWR<string[], any, any>(
    // 10 /* limit */,
    dirty,
    listBackups
  );

  if (error) {
    return <>{String(error)}</>;
  }

  if (data === undefined) {
    return <>loading...</>;
  }

  return (
    <>
      {data.map(timestamp => (
        <HStack gap={'small'} key={timestamp}>
          {timestamp} -&nbsp;
          <ActionLink
            onPress={async () => {
              await userManager.restore(timestamp);
              markDirty();
            }}
          >
            [restore]
          </ActionLink>
          &nbsp;-{' '}
          <ActionLink
            onPress={async () => {
              await userManager.removeBackup(timestamp);
              markDirty();
            }}
          >
            [remove]
          </ActionLink>
          &nbsp;-{' '}
          <ActionLink
            onPress={async () => {
              const data = await userManager.fetchBackupData(timestamp);
              // eslint-disable-next-line no-console
              console.log(data); // todo: figure out how to get log.info to display object data
              (window as any).data = data;
            }}
          >
            [inspect]
          </ActionLink>
        </HStack>
      ))}
    </>
  );
};

const ProgressInfo = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;
  const { userData } = userManager;

  return (
    <>
      <p>
        User data - updated: {userData.updatedTimeIso}, {userData.updatedGuid};{' '}
        refreshed: {agePretty(userManager.userDataRefreshedAt)}
      </p>
      {/* <p>
        <ActionLink
          onPress={() => AppFactory.caliServerInvoker.exportVocabPoc()}
        >
          [export vocab WIP]
        </ActionLink>
      </p> */}
      <VSpacer size={4} />

      <p>engagement streak: {userData.currentStreak}</p>
      <p>
        Total progress records: {userData.storyProgressList.length} -{' '}
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              await userData.resetAllProgresses();
            }
          }}
        >
          [Reset ALL progress records]
        </ActionLink>
      </p>
      <p>
        Empty progress records: {userData.emptyProgresses.length} -{' '}
        <ActionLink onPress={async () => await userData.pruneEmptyProgresses()}>
          [Prune empty]
        </ActionLink>
      </p>
      <p>Borked progress records: {userData.borkedProgresses.length}</p>
      <p>
        Orphaned progress records: {userData.orphanedProgresses.length} -{' '}
        <ActionLink
          onPress={async () => await userData.pruneOrphanedProgresses()}
        >
          [Prune orphaned]
        </ActionLink>
      </p>
      {/* <p>
        Unit progress records: {userData.unitProgresses.length} -{' '}
        {userData.unitProgresses.map(progress => (
          <>{progress.slug}, </>
        ))}
      </p> */}
      <p>
        Merge needed records: {userData.mergeNeededProgresses.length} -{' '}
        {/* <ActionLink onPress={() => userData.migrateBogotaUserData()}>
          [Migrate legacy data]
        </ActionLink> */}
      </p>
      <p>
        Records with unexpected bogota vocab slugs:{' '}
        {userData.progressesWithUnexpectedBogotaVocab.length}
        {/* -{' '}
        <ActionLink onPress={() => userData.migrateVocabSlugs()}>
          [Migrate vocab slugs]
        </ActionLink> */}
      </p>
      <p>
        Vocab migration pending records:{' '}
        {userData.vocabMigrationPendingProgresses.length}-{' '}
        {/* <ActionLink onPress={() => root.migrateAllPendingBogotaVocabs()}>
          [Migrate all pending bogota vocabs]
        </ActionLink> */}
      </p>
      <p>Total saved vocabs: {userData.totalSavedVocabs}</p>
      <h4>Borked migration</h4>
      {userData.borkedProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, vocabs.len: {progress.borkedVocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
        </p>
      ))}
      <h4>Orphaned</h4>
      {userData.orphanedProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, state: {progress.storyState}, vocab count:{' '}
          {progress.vocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
        </p>
      ))}
      <h4>Relevant</h4>
      {userData.relevantProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, state: {progress.storyState}, vocab count:{' '}
          {progress.vocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
          {progress.hasPendingBogotaVocabs ? (
            <>
              {', '}
              mig pending vocabs: {progress.pendingBogotaVocabCount}
            </>
          ) : null}
          {progress.hasOrphanedVocabs ? (
            <>
              {', '}
              orphaned vocabs:{' '}
              <strong>{progress.orphanedVocabs.join(', ')}</strong>
            </>
          ) : null}{' '}
        </p>
      ))}
      <h4>Empty</h4>
      {userData.emptyProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, state: {progress.storyState}, vocab count:{' '}
          {progress.vocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
        </p>
      ))}
      <p>
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              await userData.resetAllListeningLogs();
            }
          }}
        >
          [Reset ALL listening logs]
        </ActionLink>
        {' - '}
        <ActionLink
          onPress={() => {
            userData.createRandomListeningLog();
          }}
          label={'Spoof Listening Data'}
        />
      </p>
    </>
  );
});

const ConfigInfo = observer(() => {
  return (
    <>
      <Definition label={'devMode'} value={String(devMode)} />
      <Definition
        label={'meta.env'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(import.meta.env, null, 2)}
            readOnly
          />
        }
      />
      <Definition
        label={'config'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(appConfig, null, 2)}
            readOnly
          />
        }
      />
    </>
  );
});
