import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { createLogger } from 'app/logger';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PlayerMode } from '@common/misc-types';
import { StudyData } from '@tikka/client/catalog-types';
import { reaction } from 'mobx';
import { StudyUI } from 'study/views/study-ui';
import { getSnapshot } from '@ts-state-tree/tst-core';
import { FullScreenLoader } from 'components/ds/modals';
import { pick } from 'lodash';
import { PlayerModel } from 'player/models/player-model';
import { learnStorySlugPath } from 'components/nav/path-helpers';
import { GenericError } from '@core/lib/errors';
import __ from '@core/lib/localization';
import { ChapterRef } from '@core/models/user-manager/location-pointer';
import { isNetworkError } from '@core/lib/error-handling';
import { bugsnagNotify, sanitizeErrorMessage } from '@app/notification-service';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import { NotFoundScreen } from 'routes/not-found-screen';
import { track } from '@app/track';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const log = createLogger('study-screen');

export const StudyScreen = () => {
  const { model, failure, storySlug } = useStudyLoader();

  log.info(
    `StudyScreen render - model: ${String(!!model)}, failure: ${String(
      failure
    )}`
  );

  if (failure) {
    // beware, duplicated code from vocab content error handling
    // todo: figure out how to better factor this

    const error = failure instanceof Error ? failure : Error(String(failure));

    log.error(`prepareModel failed: ${error?.stack}`);
    if (!isNetworkError(error)) {
      bugsnagNotify(error);
    }
    const message = sanitizeErrorMessage(error);
    presentSimpleAlert(<>{message}</> /*, onErrorAlertDismiss*/);

    return <Navigate to={learnStorySlugPath(storySlug)} replace />;
  }

  if (model === undefined) {
    return <FullScreenLoader />;
  }

  if (model === null) {
    return <NotFoundScreen reportError={false} />;
  }
  return <StudyUI />;
};

interface LoaderState {
  model?: PlayerModel;
  failure?: Error;
}

const useStudyLoader = () => {
  const {
    slug,
    unitNumber: unitNumberStr,
    chapterPosition: chapterPositionStr,
  } = useParams<{
    slug: string;
    unitNumber: string;
    chapterPosition: string;
  }>();

  const storySlug = slug;
  const [queryParams /*, setSearch*/] = useSearchParams();
  const playerMode = queryParams.get('stage') as PlayerMode;
  const startMillis = Number(queryParams.get('ms'));

  // @armando: is there a better way to parse numeric router params
  const unitNumber = Number(unitNumberStr);
  const chapterPosition = Number(chapterPositionStr);

  // follow the spirit of the useSWR state flow without the cache
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { model, failure } = loaderState;

  React.useEffect(() => {
    if (model === undefined) {
      const chapterRef = { unit: unitNumber, chapter: chapterPosition };
      track('study__player_opened', {
        storySlug,
        unitNumber,
        chapterPosition,
        playerMode,
        startMillis,
      });
      prepareModel({ storySlug, chapterRef, playerMode, startMillis })
        .then(model => setLoaderState({ model }))
        .catch(failure => setLoaderState({ failure }));
      return;
    }

    if (!model || failure) {
      return;
    }

    // automatically persist progress when end of chapter reached
    const reactionDisposer = reaction(
      () => model.afterNotionalCompletion,
      atEnd => {
        if (atEnd) {
          updateProgress();
        }
      }
    );

    return () => {
      log.debug('useEffect cleanup');
      if (reactionDisposer) {
        reactionDisposer();
      }
      model.dispose();
    };
  }, [
    model,
    failure,
    storySlug,
    unitNumber,
    chapterPosition,
    playerMode,
    startMillis,
  ]);

  return {
    model,
    failure,
    storySlug,
  };
};

const prepareModel = async ({
  storySlug,
  chapterRef,
  playerMode,
  startMillis,
}: {
  storySlug: string;
  chapterRef: ChapterRef;
  playerMode: PlayerMode;
  startMillis: number;
}): Promise<PlayerModel> => {
  log.info(
    `prepareModel: ${storySlug}, ${JSON.stringify(
      chapterRef
    )}, ${playerMode}, ${startMillis}`
  );
  const { storyManager } = AppFactory.root;
  const story = storyManager.story(storySlug);
  if (!story) {
    log.error(`story not found for slug: ${storySlug}`);
  }

  const chapter = story?.chapterForPoint(chapterRef);

  if (!story || !chapter) {
    return null;
  }

  if (story?.locked) {
    throw new GenericError(`study screen - invalid access (story locked)`, {
      userMessage: __('Subscription required', 'subscriptionRequired'),
    });
  }

  const response = await AppFactory.assetCacher.maybeCachedResponse(
    chapter.playerDataUrl
  );
  const studyData = (await response.json()) as StudyData;
  const model = AppFactory.newStudyModel();
  await model.initFromStudyData(studyData);

  const sessionCounter = story.progressMayBeNull?.nextSessionCounter || 1;

  await model.initChapterSession({
    chapter,
    playerMode,
    startMillis,
    sessionCounter,
  });

  log.debug('after initChapterSession');
  const { playerSettings } = AppFactory.root.userManager.userData;
  if (playerMode === PlayerMode.STUDY) {
    model.player.setPlaybackRate(playerSettings.playbackRate);
    if (startMillis === 0 /*&& !model.suppressOnloadModal*/) {
      log.info('onloadModalNeeded startMillis', startMillis);
      model.setOnloadModalNeeded(true); // triggers auto-open of chapter notes
    }
  }

  // triggers global redaction tip
  if (startMillis === 0 && OnboardingService.instance.onPlayerOpened()) {
    // suppresses the auto-open of chapter notes if we're triggerring a tip
    // model.suppressOnloadModal = true;
    model.setOnloadModalNeeded(false);
  }

  model.setRedactionMode(playerSettings.redactionMode);
  model.setReady(); // if not set, then model.dispose is short circuited. @jason, please confirm if this makes sense or isn't relevant
  // @jfe if this is the approach we want to take shouldn't the model set itself ready inside initChapterSession?

  AppFactory.root.userManager.handlePlayerVisited(); // hook to trigger delayed mailing list prompt
  return model;
};

// todo: figure out better home for this
export const updateProgress = () => {
  const model = AppFactory.studyModel;
  const story = model.chapter.story;

  log.debug(
    `updateProgress: eoc: ${model.completionReached}, cp: ${model.studyData.position}, sess counter: ${model.sessionCounter}, millis: ${model.furthestMillis}`
  );

  // effectively waits until end of session to persist any settings changes
  const { playerSettings } = AppFactory.root.userManager.userData;

  // will get persisted along with progress below
  if (model.playerMode === PlayerMode.STUDY) {
    playerSettings.setAll({
      playbackRate: model.player.playbackRate,
      redactionMode: model.redactionMode,
    });
  }
  log.debug(`player settings: ${JSON.stringify(getSnapshot(playerSettings))}`);

  const sessionData = pick(model, [
    'chapterRef',
    'sessionCounter',
    'completionReached',
    'furthestMillis',
    'startingMode',
  ]);
  story.progress.recordSession(sessionData);
};
