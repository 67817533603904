import * as React from 'react';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';

const QuestionWrapper = styled('div', {
  '& p': {
    textStyle: 'body',
    marginBottom: '16px',
  },
  '& strong': {
    textStyle: 'body-bold',
  },
  '& blockquote': {
    margin: '0 0 16px 24px',
  },
  '& blockquote h1': {
    textStyle: 'body',
    fontStyle: 'italic',
    marginBottom: '0',
  },
  '& blockquote p': {
    textStyle: 'body',
    marginBottom: '0',
    color: '$black-alpha-50',
  },
});

export const QuestionMarkdown = ({ source }: { source: string }) => (
  <QuestionWrapper>
    <PlainMarkdown source={source} />
  </QuestionWrapper>
);
