import { styled } from '@naan/stitches.config';
import { CIRCLE_RADIUS } from './circle-icon';

export const BoxWithTrainTrack = styled('div', {
  position: 'relative',
  '&::before': {
    content: '""',
    width: 3,
    position: 'absolute',
    left: CIRCLE_RADIUS - 1,
    top: 0,
    bottom: -16,
    background: '$gray-100',
  },
});
