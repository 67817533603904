import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { l2Url } from 'components/nav/path-helpers';
import { JwSymbol } from 'components/branding/jw-symbol';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';

const Wrapper = styled('div', {
  background: '$blue-800',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 'var(--sab)',
  paddingTop: 'var(--sat)',

  '& .inner': {
    width: 'min(100% - 32px, 360px)',
    '& .logo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 40,
      '& img': {
        width: 151,
      },
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid $white-alpha-20',

      '& li': {
        margin: 0,
        padding: '8px 0',
        borderBottom: '1px solid $white-alpha-20',
        '& a': {
          textDecoration: 'none',
          padding: '4px 0',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          '& .label': {
            color: '$white',
            textStyle: 'small-heading',
            flex: 1,
          },
          '& .icon': {
            width: 32,
            height: 32,
            padding: 6,
            backgroundColor: '$teal-500',
            color: '$white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
          },
        },
      },
    },
  },
});

export const L2Choose = () => {
  return (
    <Wrapper>
      <div className="inner">
        <div className="logo">
          <JwSymbol
            presentation="colorOnDark"
            css={{ width: 'auto', height: 'auto' }}
          />
        </div>
        <ul>
          <li>
            <a href={l2Url('es')}>
              <span className="label">I’m learning Spanish</span>
              <span className="icon">
                <ArrowRightIcon />
              </span>
            </a>
          </li>
          <li>
            <a href={l2Url('en')}>
              <span className="label">Eu estou aprendendo Inglês</span>
              <span className="icon">
                <ArrowRightIcon />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};
