import * as React from 'react';
import { observer } from 'mobx-react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { Story } from '@core/models/story-manager/story';
import { VocabListModal } from 'components/story-detail/vocab-list';
import { VocabListBadge } from '../stories/vocab-list-badge-icon';
import { elementIds } from 'components/dom-utils/element-ids';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';
// import { Responsive } from '@naan/primitives/responsive';
// import { Button } from '@naan/primitives/button';
// import __ from '@core/lib/localization';

/// @elliottjf Well, this didn't work.
/// Using the vocab badge as an icon doesn't really work because it distorts the button.
/// I am trying another approach later.
// export const ExtendedVocabListStoryButton = observer(
//   ({ story }: { story: Story }) => {
//     return (
//       <Responsive
//         renderDefault={() => <VocabListButton vocabCount={story.vocabCount} />}
//         renderMediumAndUp={() => (
//           <Button
//             leftIcon={
//               <VocabListBadge css={{ color: 'currentColor' }}>
//                 {story.vocabCount}
//               </VocabListBadge>
//             }
//             label={__('Vocabulary', 'Vocabulary')}
//           />
//         )}
//       />
//     );
//   }
// );

type VocabListButtonProps = {
  textColor?: string;
  iconColor?: string;
  vocabCount: number;
  responsive?: boolean;
};

export const VocabListStoryButton = observer(
  ({
    story,
    ...props
  }: { story: Story } & Omit<VocabListButtonProps, 'vocabCount'>) => {
    return <VocabListButton vocabCount={story.vocabCount} {...props} />;
  }
);

const ResponsiveWrapper = styled('div', {
  $$color: 'currentColor',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    opacity: 0.8,
  },
  '& .button-with-label': {
    all: 'unset',
    display: 'none',
    cursor: 'default',
    paddingRight: 12,
    marginLeft: -2,
    '& .label': {
      color: '$$color',
      textStyle: 'body-bold',
    },
    '@large': {
      display: 'block',
    },
  },
});

export const VocabListButton = ({
  textColor = 'currentColor',
  iconColor = 'white',
  vocabCount,
  responsive = false,
}: VocabListButtonProps) => {
  const showingModal = useSwitch2(false);

  return (
    <>
      <ResponsiveWrapper
        css={{
          $$color: iconColor,
        }}
      >
        <VocabListBadge
          onClick={showingModal.toggle}
          css={{ color: textColor }}
          id={elementIds.VOCAB_BUTTON}
        >
          {vocabCount}
        </VocabListBadge>
        {responsive ? (
          <button
            className="button-with-label"
            onClick={e => {
              e.preventDefault();
              showingModal.toggle();
            }}
          >
            <div className="label">{__('Vocabulary', 'vocabulary')}</div>
          </button>
        ) : null}
      </ResponsiveWrapper>
      {showingModal.value && <VocabListModal onDismiss={showingModal.toggle} />}
    </>
  );
};
