import { LocaleCode } from '@utils/util-types';
import { L2s, PricingLevel, PricingLevels, StripeProductId } from './cas-types';

export class StripeProduct {
  id: StripeProductId;
  l2: LocaleCode; // = 'es';
  pricingLevel: PricingLevel; // = 'retail';

  constructor({
    l2,
    pricingLevel,
  }: {
    l2: LocaleCode;
    pricingLevel: PricingLevel;
  }) {
    this.id = StripeProduct.buildId({ l2, pricingLevel });
    this.l2 = l2;
    this.pricingLevel = pricingLevel;
  }

  static buildId({
    l2,
    pricingLevel,
  }: {
    l2: LocaleCode;
    pricingLevel: PricingLevel;
  }): StripeProductId {
    return `${l2}-${pricingLevel}`;
  }

  get stripeDescription(): string {
    return `${this.descriptionBase}${this.descriptionQualifier}`;
  }

  get descriptionBase(): string {
    switch (this.l2) {
      case 'es':
        return 'Jiveworld Español';
      case 'en':
        return 'Jiveworld English';
      default:
        return undefined;
    }
  }

  get descriptionQualifier(): string {
    switch (this.pricingLevel) {
      case 'retail':
        return '';
      case 'affiliate':
        return ' - Select discount';
      case 'student':
        return ' - Student pricing';
      default:
        return undefined;
    }
  }

  static get all(): StripeProduct[] {
    return products;
  }
}

export const products: StripeProduct[] = [];
L2s.forEach(l2 => {
  PricingLevels.forEach(pricingLevel => {
    products.push(new StripeProduct({ l2, pricingLevel }));
  });
});

export type StripeProductLookup = {
  // eslint-disable-next-line no-unused-vars
  [id in StripeProductId]?: StripeProduct;
};

export const productLookup: StripeProductLookup = {};
products.forEach(product => {
  productLookup[product.id] = product;
});

export const getStripeProduct = ({
  l2,
  pricingLevel,
}: {
  l2: LocaleCode;
  pricingLevel: PricingLevel;
}): StripeProduct => {
  return productLookup[StripeProduct.buildId({ l2, pricingLevel })];
};
