import * as React from 'react';
import { Button } from 'naan/primitives/button';
import { Heading } from 'components/ds/common/heading';
import { styled } from 'naan/stitches.config';
import {
  ExampleOnboardingContent,
  runOnboardingDialog,
} from 'components/ui/onboarding/onboarding-dialogs';

import { Image } from 'naan/primitives/image';
// import arrowImage from 'naan/primitives/menus/dropdown-arrow.png';

import { HStack } from '@naan/primitives/stack';
import { HSpacer } from '@naan/primitives/spacer';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';

const Wrapper = styled('div', {
  p: '$space$6',
  height: '200%',
});

const Box = styled('div', {
  display: 'flex',
  placeContent: 'center',
  padding: 20,
  margin: 20,
  background: '$teal-50',
});

export const ArrowImage = styled(Image, {
  position: 'relative',
  '@large': {
    left: '-24px',
  },
});

export const pathname = '/onboarding';
export const navlabel = 'Onboarding';

const OnboardingPopoverDemo = () => {
  const [showPopover, setShowPopover] = React.useState(true);
  const [dismissed, setDismissed] = React.useState(false);
  return (
    <>
      <HStack justify={'space'} css={{ width: '100%' }}>
        <div>
          <Button
            label={showPopover ? 'Hide popover' : 'Show popover'}
            onClick={() => {
              setShowPopover(!showPopover);
            }}
          />
        </div>
        <HSpacer expand />
        <OnboardingPopover
          dismissed={dismissed}
          withOverlay
          showPopover={showPopover}
          onResolvePopover={() => {
            setDismissed(true);
            setShowPopover(false);
          }}
          renderAnchor={({ ref, getProps }) => (
            <div
              ref={ref}
              style={{
                background: 'yellow',
                padding: '8px 12px',
              }}
              {...getProps()}
            >
              The tooltip willbe anchored here
            </div>
          )}
          renderBody={() => <ExampleOnboardingContent />}
        />
      </HStack>
    </>
  );
};

export const Screen = () => {
  return (
    <Wrapper>
      <Heading>Onboarding</Heading>
      <Box>
        <Button
          onClick={() =>
            void runOnboardingDialog('addToVocab', <ExampleOnboardingContent />)
          }
          label="Show onboarding dialog"
        />
      </Box>
      <Box css={{ height: '50vh' }} />
      <Box>
        <OnboardingPopoverDemo />
      </Box>
    </Wrapper>
  );
};
