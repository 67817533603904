import * as React from 'react';
import { ContentGrid } from 'components/global-layout';
import { AccountForm } from 'components/account/account-form';
// import { AccountFlash } from 'components/account/account-flash';
import { MembershipTable } from 'components/account/membership-table';
import { CheckoutResultTarget } from 'components/account/checkout-result-target';
// import { AccountCta } from 'components/account/account-cta';
import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { observer } from 'mobx-react';
import { JoinedClassrooms } from './joined-classrooms';
import { TableLayoutWrapper } from 'components/account/account-layout';
import { Button } from 'naan/primitives/button';
import { SignOutExtraSmallIcon } from 'naan/icons/sign-out-icon';
import { QuickLinks } from 'components/account/quick-links';
import { LargeGap, SectionEnds } from '@naan/primitives/spacer/gaps';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppFactory } from '@app/app-factory';
import { logoutPath, signInPath } from 'components/nav/path-helpers';
// import { useAccountLocale } from 'lib/hooks/use-account-locale';
import __ from 'core/lib/localization';
import { WithAccountLocale } from 'lib/with-account-locale';
// import { AdvancedSettingsButton } from 'components/account/settings-modal';
// import { Box } from '@naan/primitives/box';
// import { createLogger } from '@common/log';
// const log = createLogger('account-screen');

export const AccountScreen = observer(() => {
  useTitle(__('My account', 'myAccount'));
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   const { root } = AppFactory;
  //   log.info(`activating account locale`);
  //   root.activateAccountLocale();

  //   return () => {
  //     log.info(`restoring normal locale`);
  //     root.deactivateAccountLocale();
  //   };
  // });
  // useAccountLocale();

  // @armando, do you want to take pass at better factoring this and applying to more screens that don't
  // make sense to access anonymously.
  const { authenticated } = AppFactory.root.userManager;
  if (!authenticated) {
    const after = window.location.pathname;
    return <Navigate to={signInPath({ after })} replace />;
  }

  return (
    <WithAccountLocale>
      <ContentGrid>
        <PageHeading
          title={__('My account', 'myAccount')}
          showAccessoryInSmall
          renderAccessory={() => {
            /// Added so we can logout form a non-teacher account.
            return (
              // nuwan,
              <Button
                size={'small'}
                presentation={'grayLight'}
                leftIcon={<SignOutExtraSmallIcon />}
                label={__('Sign out', 'signOut')}
                onClick={() => navigate(logoutPath())}
              />
            );
          }}
        />
        {/* <AccountFlash /> */}
        <CheckoutResultTarget />
        {/* <AccountCta /> */}
        <TableLayoutWrapper>
          <JoinedClassrooms />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <AccountForm />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <MembershipTable />
        </TableLayoutWrapper>
      </ContentGrid>
      <QuickLinks />
      <LargeGap />

      {/* <Box css={{ display: 'flex', justifyContent: 'center' }}>
        <AdvancedSettingsButton />
      </Box> */}
      {/* only on the dev-tools screen now */}
      {/* <MergeProgressButton /> */}
      {/* <LargeGap /> */}
      <SectionEnds />
    </WithAccountLocale>
  );
});

export default AccountScreen;
