// TODO: find a better place for this
// NOTE this is not the end shape of localizable long texts

// @armando, how do you want to approach localizing this?
export const instructions = `

# %{productName}


%{productName} is designed to help improve your real-world Spanish listening.
You'll hear podcasts from the award-winning Radio Ambulante
— true stories from real people all over Latin America.

It's challenging material, but Jiveworld helps you out with vocab, hints,
notes and even translations so you can get by. Use as little of the help as possible,
and use your ears as much as possible, so you're better prepared when you
meet native Spanish speakers outside the classroom.

GETTING STARTED

Tap the following link, and create your Jiveworld account with your student email address:

%{invitationUrl}

Confirm on your account page that you're enrolled in %{className}.

* Check that you're signed in with the correct email address
* If you don't see yourself enrolled, tap Redeem a code and enter %{code}
`;
