import * as Sentry from '@sentry/react';
import { appConfig } from '@app/env';

import { globalErrorHandler } from 'global-error-handler';
import { createLogger } from '@common/log';

// it's undesired to import AppFactory here because off all the downstream module dependencies
// import { AppFactory } from '@app/app-factory';

const log = createLogger('bug-reporting-init');

log.debug('bug-reporting-init.ts');

// beware this could live in decs.d.ts once we replace the json schema parser
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    miscErrorContextResolver: () => Record<string, unknown>;
  }
}

const errorReportingEnabled = appConfig.sentry.enabled;

if (errorReportingEnabled) {
  const {
    tracesSampleRate,
    enableReplay,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  } = appConfig.sentry;

  const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
    const { originalException } = hint;
    if (originalException) {
      globalErrorHandler(originalException);
    }

    // loosly bound dependency assigned by AppRoot.bindMiscErrorContextResolver()
    const resolver = window.miscErrorContextResolver;
    if (resolver) {
      // capture properties which might change since the last call to setReportingContext
      event.contexts = event.contexts || {};
      event.contexts.miscState = resolver();
    } else {
      log.warn(`miscErrorContextResolver not yet assigned`);
    }

    return event;
  };

  const integrations = [];
  integrations.push(new Sentry.BrowserTracing());

  if (enableReplay) {
    integrations.push(new Sentry.Replay());
  }

  log.debug('Sentry.init');
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    integrations,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    beforeSend,
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    // todo: understand `autoSessionTracking`
    // https://docs.sentry.io/platforms/javascript/configuration/options/?original_referrer=https%3A%2F%2Fjiveworld.sentry.io%2F#ignore-errors

    // https://docs.sentry.io/platforms/javascript/configuration/options/?original_referrer=https%3A%2F%2Fjiveworld.sentry.io%2F#ignore-errors
    // ignoreErrors - can provide regex patterns to ignore
  });
} else {
  log.debug('Sentry disabled');
  window.onerror = globalErrorHandler; // revisit this someday, API doesn't quite match
  window.onunhandledrejection = globalErrorHandler;
}

// test localStorage support during module init, so it'll trigger the low-level error screen
// with a more useful messaging than the oops screen
localStorage.getItem('canary');
