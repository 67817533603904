import * as React from 'react';
import {
  ResponsiveGridLayout,
  ResponsiveNavContainer,
  ResponsiveMainContainer,
  ResponsiveParentContainer,
} from 'components/global-layout';
import { LearnNavigation } from 'components/nav/learn-navigation';
import { GlobalMessageTarget } from '../ui/global-message-target';
import { ReturnNavState } from 'components/nav/return-nav-state';
// import { AppFactory } from '@app/app-factory';

export const LearnLayout: React.FC = ({ children }) => {
  // const { l2 } = useParams<{ l2: LocaleCode }>();

  // capture state for when returning from account screen
  ReturnNavState.appMode = 'learn';
  // 'es' fallback currently necessary here to handle missing :l2 path element for app/support page
  // ReturnNavState.l2 = l2 ?? 'es';
  // ReturnNavState.setL2(l2);

  // const { userData } = AppFactory.root.userManager;
  // userData.selectL2(l2);

  return (
    <ResponsiveParentContainer>
      <GlobalMessageTarget />

      <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveMainContainer>{children}</ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <LearnNavigation />
        </ResponsiveNavContainer>
      </ResponsiveGridLayout>
    </ResponsiveParentContainer>
  );
};
