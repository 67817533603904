import {
  __s,
  localizationDisabled,
  translateWithoutDefault,
} from '@core/lib/localization';
// import { StringToString } from '@utils/util-types';
import { createLogger } from '@common/log';
import { notEmpty } from '@utils/conditionals';
import { StringToString } from '@utils/util-types';

const log = createLogger('api-messages');

export interface ApiMessageData {
  message?: string; // legacy/fallback flattened english version
  messageKey?: string;
  messageFullKey?: string; // override needed because some old api's returned a non-quailified key
  messageParams?: StringToString; // substition values when relevant
}

// const apiMessages: StringToString = {
//   'api:auth:user_not_found': __('User not found', 'api:auth:user_not_found'),
//   'api:auth:invalid_password': __(
//     'Invalid password',
//     'api:auth:invalid_password'
//   ),
//   'api:auth:account_closed': __('Account closed', 'api:auth:account_closed'),
//   'api:auth:invalid_code': __('Invalid code', 'api:auth:invalid_code'),
//   'api:auth:expired_code': __('Expired code', 'api:auth:expired_code'),
//   'api:auth:short_code_emailed': __(
//     'Email sent',
//     'api:auth:short_code_emailed'
//   ),
// };

// eslint-disable-next-line no-unused-vars
const apiMessagesLocalizationSnippet = () => {
  //
  // authentication errors
  //
  __s('User not found', 'api:auth:userNotFound');
  __s('Invalid password', 'api:auth:invalidPassword');
  __s('Invalid code', 'api:auth:invalidCode');
  __s('Expired code', 'api:auth:expiredCode');
  __s('Email sent', 'api:auth:shortCodeEmailed');

  __s(
    `We couldn't find a Jiveworld account with this email`,
    'api:auth:omniauthAccountNotFound'
  );

  // __s('Google auth error: %{message}', 'api:auth:googleAuthError');
  __s('Google auth error', 'api:auth:googleAuthError');

  __s('Invalid user token', 'api:auth:invalidUserToken');
  __s('Account closed', 'api:auth:accountClosed');
  // __s('Password required', 'api:auth:passwordRequired'); // should be client-side guarded, shared now with invalidPassword
  // __s('Missing user token', 'api:auth:missingUserToken');

  // beta invite gate
  __s('Invalid invite code', 'api:auth:invalidInviteCode');
  __s('Invitation validated', 'api:auth:inviteValidated');

  // obsolete rails configuration
  // __s('Email address not yet confirmed', 'api:auth:email_not_confirmed');
  // __s(
  //   'Please contact support@jiveworld.com if you need help with your account.',
  //   'api:auth:contact_support'
  // );
  // __s(
  //   'To create an account visit the %{site} website',
  //   'api:auth:to_create_account_visit'
  // );

  __s('Auto-renew cancelled', 'api:cancelAutoRenew:success');
  __s('Auto-renew was not enabled', 'api:cancelAutoRenew:wasNotEnabled');
  __s(
    'There was an error cancelling your subscription. Customer service has been alerted.',
    'api:cancelAutoRenew:unexpectedError'
  );

  __s('Your account is now closed', 'api:closeAccount:success');

  //
  // reset password flow
  //
  __s(
    'You will receive an email with instructions on how to reset your password in a few minutes.',
    'api:passwordReset:willReceiveEmail'
  );
  __s(
    'A user with this email was not found.',
    'api:passwordReset:error:emailNotFound'
  );
  __s(
    // 'Your password has been set. Try logging in on the mobile app.',
    'Your password has been set.',
    'api:passwordReset:hasBeenSet'
  );

  // hopefully obsolete strings
  // __s('Classroom pricing', 'api:pricing:classroom');
  // __s('Select pricing', 'api:pricing:select');
  // __s('Student pricing', 'api:pricing:student');

  //
  // applyCoupon
  //
  // success
  __s('%{daysAdded} days added to full membership', 'api:applyCode:daysAdded');
  __s('Discount pricing activated', 'api:applyCode:discountPricing');
  __s(
    'Success. You have joined the classroom: %{classroomLabel}',
    'api:applyCode:joinedClassroom'
  );
  __s('Your coupon has been applied', 'api:applyCode:applied'); // unexpected success state

  // error
  __s(
    'You are already in this classroom',
    'api:applyCode:error:alreadyJoinedClassroom'
  );
  __s('Invalid coupon code', 'api:applyCode:error:invalidCode');
  __s('Coupon already redeemed', 'api:applyCode:error:alreadyRedeemed');
  __s(
    'Discount pricing is already applied to this account',
    'api:applyCode:error:discountAlreadyApplied'
  );
  __s(
    'Promotion can only be applied to new accounts',
    'api:applyCode:error:promotionOnlyNewAccount'
  );
  __s('Coupon disabled', 'api:applyCode:error:disabled');
  __s('Coupon expired', 'api:applyCode:error:expired');
  __s('Coupon not yet valid', 'api:applyCode:error:not_yet_valid'); // left camel case because of rails code factoring
  __s('Coupon used', 'api:applyCode:error:used');

  // dev/test responses
  // api:applyCode:accessExpired
  // api:applyCode:autoRenewCancelled
  // __s('Coupon code required', 'api:applyCode:error:codeRequired'); // client-side guarded
  // __s(
  //   'We cannot apply this code while you have an active monthly subscription. To redeem, first cancel your "auto renew" and then re-enter this code here. You can re-subscribe again after.',
  //   'api:applyCode:error:cannotApplyWithAutorenew' // expected to be obsolete
  // );

  // end applyCoupon

  // __s(
  //   'Coupon already redeemed',
  //   'api:send_coupon_instructions:already_redeemed'
  // );
  // __s(
  //   'Instructions to redeem gift coupon %{code} will appear shortly in your %{email} inbox. You can forward it to the giftee.',
  //   'api:send_coupon_instructions:confirmation:message'
  // );
  // __s('Email sent', 'api:send_coupon_instructions:confirmation:title');
  // __s('Coupon code not found', 'api:send_coupon_instructions:not_found');
  // __s('Coupon not valid', 'api:send_coupon_instructions:not_valid');

  __s('Vocab list emailed to %{email}', 'api:sendVocabData:emailed');
  __s('No saved vocab', 'api:sendVocabData:error:noSaved');

  //
  // update profile fields
  //
  __s('Confirmation email sent', 'api:update:confirmationEmailSent');
  __s('Updated', 'api:update:updated');
  __s('Required', 'api:update:error:required');
  // __s('%{field} updated', 'api:update:updated');
  // __s('%{field} must be provided', 'api:update:must_be_provided');

  __s(
    'Email reset back to %{email}',
    'api:cancelPendingEmailChange:emailReset'
  );
  __s(
    'No pending email. Left as %{email}',
    'api:cancelPendingEmailChange:noPending'
  );
  // __s('Previous email restored', 'api:cancel_pending_email_change:restored');
};

// activerecord:
// attributes:
//   user:
//     email: Email
//     name: Name
//     password: Password
// models:
//   user: User

// export const resolveApiMessage = ({
//   messageKey,
//   message,
// }: {
//   messageKey?: string;
//   message: string; // server provided string
// }): string => {
export const resolveApiMessage = (apiMessage: ApiMessageData): string => {
  if (!apiMessage) {
    return undefined;
  }
  const { messageFullKey, messageKey, message, messageParams } = apiMessage;
  let rawKey = messageFullKey || messageKey;
  if (notEmpty(rawKey) && !localizationDisabled) {
    // dot separators are sent by the server, but caused problems with the loco send/download,
    // so transform to colons before resolving
    const key = rawKey.replace(/\./g, ':');
    // const result = apiMessages[key];
    const result = translateWithoutDefault(key, messageParams);

    if (result) {
      return result;
    } else {
      log.warn(`messageKey not resolved: ${messageKey}`);
    }
  }
  return message; // fall back to server provided message if key not provided or resolved
};
