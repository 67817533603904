// const formatterWithCents = () =>
//   new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 2,
//   });

// const formatterNoCents = () =>
//   new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 0,
//   });

// export const formatMoney = (number: number) => {
//   const withCents = number % 1 !== 0;

//   if (withCents) {
//     return formatterWithCents().format(number);
//   }

//   return formatterNoCents().format(number);
// };

export const formatMoney = (number: number, currency: string /* = 'USD'*/) => {
  currency = currency.toUpperCase();
  const withCents = number % 1 !== 0;
  const formattedNumber = withCents ? number.toFixed(2) : number.toFixed(0);

  if (currency === 'USD') {
    return `$${formattedNumber}`;
  } else if (currency === 'BRL') {
    return `R$ ${formattedNumber}`;
  } else {
    throw new Error(`Unsupported currency: ${currency}`);
  }
};
