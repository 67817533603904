import { AlertIcon } from 'naan/icons/alert-icon';
import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { CheckmarkHeavyIcon } from 'naan/icons/checkmark-heavy-icon';
import { CloseIcon } from 'naan/icons/close-icon';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { Button, IconButton } from 'naan/primitives/button';
import { styled } from 'naan/stitches.config';
import * as React from 'react';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$green-500',
  $$textColor: '$colors$white',
  $$linkColor: '$$textColor',
  $$iconColor: '$colors$white',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$space$4',
  backgroundColor: '$$backgroundColor',
  color: '$$textColor',

  '& > .inner': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'min(100%, calc(960px - $space$8 ))',
    // position: 'relative',
    // background: '$colors$black-alpha-10',

    '&:has(> .close-button)': {
      paddingRight: '$space$8',
      paddingLeft: '$space$4',
    },

    '@large': {
      margin: '0 auto',
    },

    '& > span+span': {
      px: 0,
      marginLeft: '$space$2',
    },

    '& span.label': {
      textStyle: 'body-bold',
    },

    '& span.icon': {
      lineHeight: 0,
    },

    '& span.close-button': {
      position: 'absolute',
      right: '$space$3',
    },

    '& a': {
      textStyle: 'body',
      color: '$$linkColor',
    },
  },

  variants: {
    type: {
      info: {
        $$backgroundColor: '$colors$blue-500',
        $$iconColor: '$colors$white',
        $$textColor: '$colors$white',
      },
      success: {
        $$backgroundColor: '$colors$green-500',
        $$iconColor: '$colors$white',
        $$textColor: '$colors$white',
      },
      warning: {
        $$backgroundColor: '$colors$yellow-300',
        $$iconColor: '$colors$black-alpha-70',
        $$textColor: '$colors$black-alpha-70',
      },
      error: {
        $$backgroundColor: '$colors$red-500',
        $$iconColor: '$colors$white',
        $$textColor: '$colors$white',
      },
    },
  },
});

export type Message = {
  type: 'success' | 'warning' | 'error' | 'info';
  message: React.ReactNode;
  renderLink?: () => React.ReactNode;
  action?: {
    label: string;
    callback: () => void;
  } | null;
  dismissible?: boolean; // @armando, do you want keep this boolean prop or just rely on onDismiss?
  onDismiss?: () => void;
  elementId?: string;
};

type GlobalMessageProps = {
  type: 'success' | 'warning' | 'error' | 'info';
  message: Message;
  onDismiss?: () => void;
  elementId?: string;
};

export const GlobalMessage = ({
  type,
  message,
  onDismiss,
  elementId,
}: GlobalMessageProps) => {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoCircleIcon />;
      case 'success':
        return <CheckmarkHeavyIcon />;
      case 'warning':
        return <AlertIcon />;
      case 'error':
        return <AlertTriangleIcon />;
      default:
        break;
    }
    return null;
  }, [type]);

  const showButton = !!message.action;

  const dismissHandler = message.onDismiss || onDismiss; // not sure if we need both options
  // const showCloseButton = message.dismissible && onDismiss;
  const showCloseButton = message.dismissible && dismissHandler;

  return (
    <Wrapper type={type} id={elementId} data-testid="global-message-wrapper">
      <div className="inner">
        <span className="icon">{icon}</span>
        <span className="label">{message.message}</span>
        {message.renderLink ? (
          <span className="link">{message.renderLink()}</span>
        ) : null}
        {showButton ? (
          <span className="action-button">
            <Button
              onClick={message.action.callback}
              label={message.action.label}
              size={'small'}
              presentation="white"
            />
          </span>
        ) : null}
        {showCloseButton ? (
          <span className="close-button">
            <IconButton
              icon={<CloseIcon />}
              presentation={
                type === 'warning' ? 'grayTransparent' : 'whiteTransparent'
              }
              onClick={dismissHandler}
              testId="close-global-message"
            />
          </span>
        ) : null}
      </div>
    </Wrapper>
  );
};
