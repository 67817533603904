//
// home for stateless helper functions that otherwise break the json schema gen

import { getPlans } from '@cas-shared/plan-data';
import { Plan } from '@cas-shared/plan';
import { AppRoot } from '../app-root';

export function resolvePlans(root: AppRoot): Plan[] {
  const { l2, currency, userManager, dailySubscriptionEnabled } = root;
  const { pricingLevel } = userManager.accountData;
  return getPlans({
    l2,
    currency,
    pricingLevel,
    includeDaily: dailySubscriptionEnabled,
  });
}
