import React from 'react';
import * as PC from './pricing-cards/pricing-card';
import { Plan } from '@cas-shared/plan';
import { useStripeCheckout } from 'common/stripe-context';
import { formatMoney } from '@utils/format-money';
import { alertSevereError } from '@app/notification-service';
import { capitalize } from 'lodash';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';

import __, { addNbsps } from 'core/lib/localization';

const log = createLogger('plan-pricing-card');

function resolveIntervalLabel(plan: Plan): string {
  switch (plan.billingInterval) {
    case 'month':
      return __('Monthly', 'monthly');
    case 'year':
      return __('Yearly', 'yearly');
    case 'day':
      return 'Daily (testing)'; //__('Daily', 'daily'); // only used for internal testing, not really needed any more
    default:
      log.error(`unexpected billing interval: ${plan.billingInterval}`);
      return capitalize(plan.billingInterval);
  }
}

function resolvePricingExplanation(plan: Plan): string {
  const { isMonthly, isDiscounted, normalPrice } = plan;
  let result = isMonthly
    ? __('Billed monthly', 'billedMonthly')
    : __('Billed yearly', 'billedYearly');

  if (isDiscounted && isMonthly) {
    const formattedNormalPrice = formatMoney(normalPrice, plan.currency);
    result = __('Usually %{price}/mo', 'usuallyNMonthly', {
      price: formattedNormalPrice,
    });
  }
  return result;
}

export const useDerivedPlanVars = (plan: Plan) => {
  const {
    price,
    // pricingDescription,
    discountPercentage,
    isMonthly,
    isDiscounted,
    currency,
  } = plan;

  // let's curry this to the currency
  const formatCurrency = React.useCallback(
    (n: number) => formatMoney(n, currency),
    [currency]
  );

  const intervalLabel = resolveIntervalLabel(plan);
  const pricingExplanation = resolvePricingExplanation(plan);
  // const isCurrent = userManager.accountData.currentPlan === slug;

  const displayPrice = formatCurrency(
    isMonthly ? price : (price as number) / 12
  );
  const { pricingDescription } = AppFactory.root.userManager.accountData;

  const hasSpecialPricing = !!pricingDescription;
  let ribbonTitle = pricingDescription;

  if (hasSpecialPricing && isDiscounted) {
    ribbonTitle = __(
      '%{pricingDescription}: save %{discountPercentage}%',
      'discountedRibbonTitle',
      {
        pricingDescription,
        discountPercentage,
      }
    );
  } else if (isDiscounted) {
    ribbonTitle = __(
      'Save %{discountPercentage}%',
      'savePercentageRibbonTitle',
      {
        discountPercentage,
      }
    );
  }

  const showRibbon = hasSpecialPricing || isDiscounted;
  const showOriginalPrice = isDiscounted && !isMonthly;

  return {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    formatCurrency,
  } as const;
};

const useStripeCheckoutHandler = (plan: Plan) => {
  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    const { userManager } = AppFactory.root;
    userManager
      .initiateCheckout(plan)
      .then(result => {
        // if (result.interstitialMessageKey) {
        //   // can hopefully remove the interstitial flow
        //   launchCheckoutInterstitial(result, () =>
        //     stripeCheckout(result.stripeSessionId)
        //   );
        // } else {
        return stripeCheckout(result.stripeSessionId);
        // }
      })
      .catch(error => {
        alertSevereError({ error, note: 'PlanPricingCard.handleCheckout' });
      });
  }, [plan, stripeCheckout]);

  return handleCheckout;
};

export const PlanPricingCard = ({ plan }: { plan: Plan }) => {
  const { price, normalPrice, isMonthly, diminutiveCurrencyPrefix } = plan;

  const {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    formatCurrency,
  } = useDerivedPlanVars(plan);

  const handleCheckout = useStripeCheckoutHandler(plan);

  return (
    <PC.Container presentation={isMonthly ? 'monthly' : 'one-year'}>
      {showRibbon ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <PC.Scratch className="cross">
              {formatCurrency(normalPrice)}{' '}
            </PC.Scratch>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>
      </PC.TextWrapper>

      <PC.Button
        onClick={handleCheckout}
        label={__('Subscribe', 'subscribe')}
      />
    </PC.Container>
  );
};

export const AnonymousPlanPricingCard = ({ plan }: { plan: Plan }) => {
  const { price, normalPrice, isMonthly, diminutiveCurrencyPrefix } = plan;

  const {
    intervalLabel,
    pricingExplanation,
    displayPrice,
    ribbonTitle,
    showRibbon,
    showOriginalPrice,
    formatCurrency,
  } = useDerivedPlanVars(plan);

  return (
    <PC.Container presentation={'anonymous'}>
      {showRibbon ? <PC.Ribbon ribbonTitle={ribbonTitle} /> : null}

      <PC.TextWrapper>
        <PC.Heading>{intervalLabel}</PC.Heading>
        <PC.PriceContainer>
          {!!diminutiveCurrencyPrefix ? (
            <PC.PricePrefix>{diminutiveCurrencyPrefix}</PC.PricePrefix>
          ) : null}
          <PC.PriceAmount>{displayPrice}</PC.PriceAmount>
          <PC.PriceInterval>{__('/mo', 'perMoLower')}</PC.PriceInterval>
        </PC.PriceContainer>

        <PC.Description>
          {showOriginalPrice ? (
            <PC.Scratch className="cross">
              {formatCurrency(normalPrice)}{' '}
            </PC.Scratch>
          ) : null}
          {isMonthly ? null : (
            <PC.OfferPrice>{formatCurrency(price)} </PC.OfferPrice>
          )}
          {addNbsps(pricingExplanation)}
        </PC.Description>
      </PC.TextWrapper>
    </PC.Container>
  );
};
