import * as React from 'react';

import { styled } from '@naan/stitches.config';
import { JiveworldLogo } from 'components/branding/jiveworld-logo';
import { buildInfoDisplay } from '@core/lib/app-util';
import { marketingSite } from 'components/nav/path-helpers';
import { ExternalLink } from '@naan/primitives/text/external-link';
import __ from 'core/lib/localization';
// import { ExternalLink } from '@naan/primitives/text/external-link';
// import { marketingSite } from 'components/nav/path-helpers';

const Wrapper = styled('footer', {
  paddingBottom: '$space$6',
  textStyle: 'tiny-text',
  color: '$gray-300',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  '& a': {
    color: 'inherit',
  },
  '@small': {
    padding: '24px 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid $colors$gray-100',
    '& .text': {
      textAlign: 'right',
    },
  },
});

export const DashboardFooter = () => {
  return (
    <Wrapper>
      <div className="logo">
        {/* @armando, please make this a link without changing the color */}
        <ExternalLink href={marketingSite()}>
          <JiveworldLogo />
        </ExternalLink>
      </div>
      <div className="text">
        <p>{buildInfoDisplay()}</p>
        <p>
          {__(
            'Patent Nos. 10,984,667; 11,551,568 B2;',
            'patentNos1098466711551568B2'
          )}
        </p>
      </div>
    </Wrapper>
  );
};
