import * as React from 'react';
import __ from 'core/lib/localization';
import { PlainMarkdown } from '@naan/primitives/text';
import * as Onboarding from './components';

export const TranslationTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__('Translation unlocked', 'translationTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Now you've tapped Reveal you can also check the full translation here.`,
            'translationTipBody'
          )}
        />
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
