import React from 'react';
import { observer } from 'mobx-react';
import {
  AnonymousPlanPricingCard,
  PlanPricingCard,
} from '../plan-pricing-card';
import { Plan } from '@cas-shared/plan';
import { AppFactory } from '@app/app-factory';
import { PricingCardsWrapper } from './pricing-cards-wrapper';
import { resolvePlans } from '@core/models/user-manager/user-manager-helper';

export const PricingCards = observer(() => {
  const plans: Plan[] = resolvePlans(AppFactory.root);
  return (
    <PricingCardsWrapper>
      {plans.map(plan => (
        <PlanPricingCard key={plan.slug} plan={plan} />
      ))}
    </PricingCardsWrapper>
  );
});

//
// to test this anonymous affiliate pricing, use an url in the form of:
//   http://localhost:3100/?utm_medium=alp&utm_source=fojw
//

export const AnonPricingCards = observer(() => {
  const plans: Plan[] = resolvePlans(AppFactory.root);
  return (
    <PricingCardsWrapper>
      {plans.map(plan => (
        <AnonymousPlanPricingCard plan={plan} />
      ))}
    </PricingCardsWrapper>
  );
});
