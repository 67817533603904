import * as React from 'react';
import * as Onboarding from './components';
import __ from 'core/lib/localization';

import { PlainMarkdown } from '@naan/primitives/text';
import redactionTipAnimationGif from './assets/redaction-tip-animation.gif';

export const RedactionMenuTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__(`Ready for a challenge?`, 'redactionTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Try hiding some or all of the words in the script.`,
            'redactionTipBody'
          )}
        />
      </Onboarding.Content>
      <Onboarding.Image src={redactionTipAnimationGif} />
      {/* <Onboarding.HelpletVideo /> */}
    </Onboarding.Container>
  );
};
