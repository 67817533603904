export const textStyles = {
  'medium-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '25px',
    letterSpacing: '-0.3px',
    lineHeight: '32px',
    webkitFontSmoothing: 'antialiased',
  },
  'website--nav': {
    fontFamily: 'larsseit-medium',
    fontWeight: '400',
    fontSize: '20px',
    letterSpacing: '0px',
    lineHeight: '24px',
    webkitFontSmoothing: 'antialiased',
  },
  body: {
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '17px',
    letterSpacing: '0px',
    lineHeight: '24px',
  },
  'body-bold': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '17px',
    letterSpacing: '0px',
    lineHeight: '24px',
    webkitFontSmoothing: 'antialiased',
  },
  'masala-body': {
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '0px',
    lineHeight: '21px',
  },
  serif: {
    fontFamily: 'Source Serif Pro',
    fontWeight: '400',
    fontSize: '19px',
    letterSpacing: '0px',
    lineHeight: '28px',
  },
  'serif-small': {
    fontFamily: 'Source Serif Pro',
    fontWeight: '400',
    fontSize: '17px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  'serif-italic': {
    fontFamily: 'Source Serif Pro',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '19px',
    letterSpacing: '0px',
    lineHeight: '28px',
  },
  'serif-bold': {
    fontFamily: 'Source Serif Pro',
    fontWeight: '700',
    fontSize: '19px',
    letterSpacing: '0px',
    lineHeight: '28px',
  },
  'serif-bold-italic': {
    fontFamily: 'Source Serif Pro',
    fontStyle: 'italic',
    fontWeight: '700',
    fontSize: '19px',
    letterSpacing: '0px',
    lineHeight: '28px',
  },

  stat: {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '29px',
    letterSpacing: '0px',
    lineHeight: '32px',
    webkitFontSmoothing: 'antialiased',
  },
  'script--script': {
    fontFamily: 'Source Serif Pro',
    fontWeight: '400',
    fontSize: '20px',
    letterSpacing: '0px',
    lineHeight: '36px',
  },
  'script--notes': {
    fontFamily: 'Source Serif Pro',
    fontWeight: '400',
    fontSize: '19px',
    letterSpacing: '-0.20000000298023224px',
    lineHeight: '28px',
  },
  code: {
    fontFamily: 'Monaco',
    fontPostScriptName: 'Monaco',
    fontWeight: '400',
    fontSize: '16px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  'coupon-code': {
    fontFamily: 'Roboto Mono',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '24px',
  },
  'script--chapter-heading': {
    fontFamily: 'Source Serif Pro',
    fontPostScriptName: 'Source Serif Pro',
    fontWeight: '400',
    fontSize: '24px',
    letterSpacing: '0px',
    lineHeight: '32px',
  },
  nav: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '19px',
    letterSpacing: '-0.2px',
    lineHeight: '24px',
    webkitFontSmoothing: 'antialiased',
  },
  'script--hint': {
    fontFamily: 'Source Serif Pro',
    fontStyle: 'italic',
    fontPostScriptName: 'SourceSerifPro-It',
    fontWeight: '400',
    fontSize: '20px',
    letterSpacing: '0px',
    lineHeight: '24px',
  },

  'badge-text': {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '11px',
    letterSpacing: '0px',
    lineHeight: '16px',
  },

  tag: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },

  'small-text': {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '15px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },

  'small-text-bold': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '15px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  'small-caps': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
  },
  'tiny-text': {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  'small-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '19px',
    letterSpacing: '0px',
    lineHeight: '24px',
  },
  'pricing-card-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '700',
    fontSize: '27px',
    lineHeight: '32px',
    webkitFontSmoothing: 'antialiased',
  },
  'pricing-card-price': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '44px',
    webkitFontSmoothing: 'antialiased',
  },
  'pricing-card-cta': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
  },
  'large-heading--responsive': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '30px',
    letterSpacing: '-0.5px',
    lineHeight: '36px',
    webkitFontSmoothing: 'antialiased',
    '@medium': {
      fontSize: '37px',
      lineHeight: '48px',
    },
  },
  'large-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '30px',
    letterSpacing: '-0.5px',
    lineHeight: '36px',
    webkitFontSmoothing: 'antialiased',
  },
  'extra-large-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '37px',
    letterSpacing: '-0.5px',
    lineHeight: '48px',
    webkitFontSmoothing: 'antialiased',
  },
  'huge-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: '700',
    fontSize: '50px',
    lineHeight: '56px',
    webkitFontSmoothing: 'antialiased',
  },
  'medium-handwritten-heading': {
    fontFamily: 'permanent-marker',
    fontWeight: 'normal',
    fontSize: '28px',
    letterSpacing: '-.5px',
    lineHeight: '28px',
    WebkitFontSmoothing: 'antialiased',
  },
} as const;
