import * as React from 'react';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import classNames from 'classnames';
import { QuestionMarkdown } from '@naan/primitives/text/soundbite-question-markdown';
import { AppFactory } from 'app/app-factory';
import { AnswerState } from 'soundbite/models/soundbite-model';
import { scrollTo } from 'soundbite/fx/scrolling';

import { __, __f } from '@core/lib/localization';

export const ANSWER_CARD_ID = 'answer-card;';

const arrow = (
  <svg
    width={28}
    height={48}
    viewBox="0 0 28 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4996 6.00002C16.4996 6.00002 22.5 22 15.6833 45.8009M15.6833 45.8009C15.6833 45.8009 22 42 26.5 34.5M15.6833 45.8009C15.6833 45.8009 12.5 39 7.49956 33.742"
      stroke="#383838"
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
  </svg>
);

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$green-200',
  background: '$$backgroundColor',
  padding: 16,

  '&.teaser': {
    $$backgroundColor: '$colors$black-alpha-06',
    height: 128,
    position: 'relative',

    '& .message': {
      textStyle: 'small-heading',
    },
    '& .arrow': {
      position: 'absolute',
      bottom: 0,
      left: 'calc(50% - 14px)',
      zIndex: 3,
    },
  },

  '&.disabled': {
    $$backgroundColor: '$colors$black-alpha-06',
  },

  '&.unrevealed': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
  },

  '& .heading': {
    unset: 'all',
    textStyle: 'small-heading',
    color: '$colors$green-700',
    marginBottom: 16,
  },
  '@widerThanScript': {
    maxWidth: 600,
    margin: '0 auto 56px',
    borderRadius: 12,
    '&.revealed': {
      marginBottom: '0',
    },
  },
});

const revealAnswer = () => {
  AppFactory.soundbiteModel.revealAnswer();
  requestAnimationFrame(() => {
    scrollTo(document.getElementById(ANSWER_CARD_ID));
  });
};

const revealLabelFn = __f('Reveal answer', 'revealAnswer');

export const Answer = observer(({ excerpt }: { excerpt: ExcerptData }) => {
  const { answerState: state } = AppFactory.soundbiteModel;

  if (!excerpt.explanation) {
    return null;
  }

  return (
    <>
      <Wrapper
        id={ANSWER_CARD_ID}
        className={classNames({
          teaser: state === AnswerState.initial,
          disabled: state === AnswerState.disabled,
          unrevealed: state !== AnswerState.revealed,
          revealed: state === AnswerState.revealed,
        })}
      >
        {state === AnswerState.initial && (
          <>
            <span className="message">
              {__('Tap play to listen', 'tapPlayToListen')}
            </span>
            <span className="arrow">{arrow}</span>
          </>
        )}

        {state === AnswerState.disabled && (
          <div>
            <Button
              presentation={'grayLight'}
              disabled
              label={revealLabelFn()}
            />
          </div>
        )}
        {state === AnswerState.unrevealed && (
          <div>
            <Button
              presentation={'green'}
              onClick={revealAnswer}
              label={revealLabelFn()}
            />
          </div>
        )}

        {state === AnswerState.revealed && (
          <>
            <h2 className="heading">{__('Answer', 'answer')}</h2>
            <QuestionMarkdown source={excerpt.explanation} />
          </>
        )}
      </Wrapper>
    </>
  );
});
