import * as React from 'react';
import { observer } from 'mobx-react';
import { TableFieldButtonSet, TableFieldWrapper } from './table-field';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useComboForm } from 'common/hooks/use-comboform';
import { pick } from 'lodash';
import { emailSchema } from 'core/lib/validation-rules';
import { ToastService } from 'common/notifications/toast-service';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { Input } from 'naan/primitives/input';
import { VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import __ from 'core/lib/localization';
import { PendingEmailNote } from '../pending-email-note';
import { AppFactory } from '@app/app-factory';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';

type FormInput = {
  email: string;
};

export const EmailForm = observer(() => {
  const editSwitch = useSwitch2(false);
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;
  const defaultValues = pick(accountData, ['email']);

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ validationSchema: emailSchema, defaultValues });

  const handleUpdate = React.useCallback(
    ({ email }: FormInput) => {
      clearValidationErrors();
      userManager.updateEmail(email).then(result => {
        const message = resolveApiMessage(result);
        editSwitch.off();
        ToastService.open({
          message: message,
          type: 'success',
        });
      }, handleValidationErrors);
    },
    [editSwitch, userManager, clearValidationErrors, handleValidationErrors]
  );

  return (
    <TableFieldWrapper
      label={__('Email', 'email')}
      testId="email-address"
      value={
        <>
          {accountData.email}
          <PendingEmailNote />
        </>
      }
      editing={editSwitch.value}
      onStartEditing={editSwitch.on}
    >
      <form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <Input
          name={'email'}
          data-test-id={'edit-input'}
          type="text"
          {...register('email')}
        />
        {/* <FormError error={errors['email']} data-test-id="error-message" /> */}
        <CatchAllFormErrors errors={errors} data-test-id="error-message" />
        <TableFieldButtonSet
          updateLabel={__('Update', 'update')}
          cancelAction={editSwitch.off}
        />
        <>
          <VSpacer size={5} />
          <Text textStyle="small-text" color="textSecondary">
            {__(
              'A verification link will be sent to the new email address',
              'aVerificationLink'
            )}
          </Text>
        </>
      </form>
    </TableFieldWrapper>
  );
});
