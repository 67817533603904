import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { observer } from 'mobx-react';
import { keyframes, styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const Heading = styled('div', {
  padding: '16px',
  textStyle: 'small-heading',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    height: '1px',
    backgroundColor: '$gray-100',
    position: 'absolute',
    bottom: 0,
    left: 16,
    right: 16,
  },
});

const Content = styled(Dialog.HtmlContent, {
  $$maxWidth: '600px',
  backgroundColor: '$white',
  borderRadius: 12,
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  display: 'flex',
  flexDirection: 'column',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

export const SettingsModalContainer: React.FC<{ onDismiss: () => void }> =
  observer(({ children, onDismiss }) => {
    return (
      <DialogHtmlContainer
        onDismiss={onDismiss}
        contentComponent={Content as any}
      >
        <Heading>
          {__('Settings', 'settings')}
          <Dialog.HtmlCloseButton onDismiss={onDismiss} />
        </Heading>
        <Dialog.Body>{children}</Dialog.Body>
      </DialogHtmlContainer>
    );
  });
