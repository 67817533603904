import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { Button } from '@naan/primitives/button';
import { AppFactory } from 'app/app-factory';
import { VocablistMarkdown } from '@naan/primitives/text/soundbite-vocablist-markdown';
import { track } from 'app/track';
import { elementIds } from 'components/dom-utils/element-ids';
import { keyboardService } from 'lib/services/keyboard-service';

import __ from '@core/lib/localization';

export const VocabularyModal = observer(
  ({ excerpt, onDismiss }: { excerpt: ExcerptData; onDismiss: () => void }) => {
    React.useEffect(() => {
      const reset = keyboardService.addModalShortcut('v', onDismiss);
      return () => {
        reset();
      };
    });

    return (
      <Dialog.Container open onDismiss={onDismiss}>
        <Dialog.Heading>{__('Vocabulary', 'vocabulary')}</Dialog.Heading>
        <Dialog.Body>
          {/* <Divider /> */}
          {/* <VSpacer size={4} /> */}
          <VocablistMarkdown source={excerpt.vocabulary} />
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            label={__('Close', 'close')}
            presentation="grayLight"
            type="button"
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const Vocabulary = observer(({ excerpt }: { excerpt: ExcerptData }) => {
  const handleVocabularyClick = React.useCallback(() => {
    track('soundbite__vocab_shown', { soundbiteSlug: excerpt?.slug });
    AppFactory.dialogPresenter.present(onDismiss => {
      return <VocabularyModal excerpt={excerpt} onDismiss={onDismiss} />;
    });
  }, [excerpt]);

  if (!excerpt.vocabulary) {
    return null;
  }

  return (
    <Button
      size="small"
      label={__('Vocabulary list', 'vocabularyList')}
      presentation="gray"
      onClick={handleVocabularyClick}
      id={elementIds.SOUNDBITE_VOCABULARY_BUTTON}
    />
  );
});
