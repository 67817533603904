import * as React from 'react';
import { IconButton } from 'naan/primitives/button';
import { ArrowRightSmallIcon } from 'naan/icons/arrow-right-icon';
import { styled } from 'naan/stitches.config';
import { Text } from 'naan/primitives/text';

// const { colors } = theme;

const Table = styled('table', {
  width: '100%',
  alignSelf: 'flex-start',
  // background: 'red',
  '&  .presentation-whiteTransparent': {
    backgroundColor: '#666',
  },

  '&  .presentation-whiteDarken': {
    backgroundColor: '#666',
  },

  '& th': {
    textAlign: 'center',
  },

  '&  td': {
    padding: '0.5rem',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > * + *': {
        marginTop: '0.5rem',
      },
    },
  },
});

const presentations = [
  'grayTransparent',
  'tealTransparent',
  'whiteTransparent',
  'grayLight',
  'tealLight',
  'whiteDarken',
  'redLight',
];

const sizes = ['default', 'extraSmall', 'small', 'large'];

const AllButtonsAtOnce = () => {
  return (
    <>
      <Text textStyle="medium-heading" as="h2">
        Icon Buttons
      </Text>

      <Table>
        <thead>
          <tr>
            <th></th>
            {sizes.map(size => (
              <th>{size}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {presentations.map(presentation => {
            return (
              <tr className={`presentation-${presentation}`}>
                <th>{presentation}</th>{' '}
                {sizes.map(size => {
                  return (
                    <td>
                      <div>
                        <IconButton
                          size={size as any}
                          icon={<ArrowRightSmallIcon />}
                          presentation={presentation as any}
                          testId="showcase-icon-button"
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export const pathname = '/icon-buttons';
export const navlabel = 'Icon Buttons';
export const naanReady = true;

export const Screen = () => {
  return <AllButtonsAtOnce />;
};
