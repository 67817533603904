import { LocaleCode } from '@utils/util-types';
import { PricingLevel, BillingInterval, Currency, PlanSlug } from './cas-types';
import { Plan } from './plan';

const ACTIVE_PRICING_GENERATION = '2021';

const USD_RETAIL_MONTH = 12;
const USD_RETAIL_YEAR = 99;
const USD_AFF_MONTH = 10;
const USD_AFF_YEAR = 84;
const USD_STU_MONTH = 8;
const USD_STU_YEAR = 59;
const BRL_USD = 5;

const buildPlan = ({
  l2,
  currency,
  pricing,
  interval,
  price,
  normalPrice,
}: {
  l2: LocaleCode;
  currency: Currency;
  pricing: PricingLevel;
  interval: BillingInterval;
  price: number;
  normalPrice?: number;
}) =>
  new Plan({
    l2,
    currency,
    pricingLevel: pricing,
    pricingGeneration: ACTIVE_PRICING_GENERATION,
    billingInterval: interval,
    price,
    normalPrice,
  });

const plans: Plan[] = [
  // Jiveworld Espanol
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'retail',
    interval: 'month',
    price: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'retail',
    interval: 'year',
    price: USD_RETAIL_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'affiliate',
    interval: 'month',
    price: USD_AFF_MONTH,
    normalPrice: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'affiliate',
    interval: 'year',
    price: USD_AFF_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'student',
    interval: 'month',
    price: USD_STU_MONTH,
    normalPrice: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'es',
    currency: 'usd',
    pricing: 'student',
    interval: 'year',
    price: USD_STU_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),

  // we need BRL pricing for Jiveworld Espanol to avoid blowing up for the unexpected case of
  // an English product customer attempts to also subscribe to the Spanish
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'retail',
    interval: 'month',
    price: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'retail',
    interval: 'year',
    price: USD_RETAIL_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'affiliate',
    interval: 'month',
    price: USD_AFF_MONTH * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'affiliate',
    interval: 'year',
    price: USD_AFF_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'student',
    interval: 'month',
    price: USD_STU_MONTH * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'es',
    currency: 'brl',
    pricing: 'student',
    interval: 'year',
    price: USD_STU_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),

  //
  // Jiveworld English
  //
  // USD
  //
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'retail',
    interval: 'month',
    price: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'retail',
    interval: 'year',
    price: USD_RETAIL_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'affiliate',
    interval: 'month',
    price: USD_AFF_MONTH,
    normalPrice: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'affiliate',
    interval: 'year',
    price: USD_AFF_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'student',
    interval: 'month',
    price: USD_STU_MONTH,
    normalPrice: USD_RETAIL_MONTH,
  }),
  buildPlan({
    l2: 'en',
    currency: 'usd',
    pricing: 'student',
    interval: 'year',
    price: USD_STU_YEAR,
    normalPrice: USD_RETAIL_MONTH * 12,
  }),

  //
  // BRL
  //
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'retail',
    interval: 'month',
    price: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'retail',
    interval: 'year',
    price: USD_RETAIL_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'affiliate',
    interval: 'month',
    price: USD_AFF_MONTH * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'affiliate',
    interval: 'year',
    price: USD_AFF_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'student',
    interval: 'month',
    price: USD_STU_MONTH * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * BRL_USD,
  }),
  buildPlan({
    l2: 'en',
    currency: 'brl',
    pricing: 'student',
    interval: 'year',
    price: USD_STU_YEAR * BRL_USD,
    normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
  }),

  // //
  // // testing
  // //
  // buildPlan({
  //   l2: 'en',
  //   currency: 'usd',
  //   pricing: 'retail',
  //   interval: 'day',
  //   price: 1,
  // }),
  // buildPlan({
  //   l2: 'en',
  //   currency: 'brl',
  //   pricing: 'retail',
  //   interval: 'day',
  //   price: 5,
  // }),
  // buildPlan({
  //   l2: 'es',
  //   currency: 'usd',
  //   pricing: 'retail',
  //   interval: 'day',
  //   price: 2,
  // }),
  // buildPlan({
  //   l2: 'es',
  //   currency: 'brl',
  //   pricing: 'retail',
  //   interval: 'day',
  //   price: 6,
  // }),
];

type PlanLookup = {
  // eslint-disable-next-line no-unused-vars
  [key in PlanSlug]?: Plan;
};

const planLookup: PlanLookup = {};
plans.forEach(plan => {
  planLookup[plan.slug] = plan;
});

export const allPlans = () => plans;

export const getPlanBySlug = (slug: PlanSlug): Plan => {
  return planLookup[slug];
};

export const getPlans = ({
  l2,
  pricingLevel,
  currency,
  includeDaily,
}: {
  l2: LocaleCode;
  pricingLevel: PricingLevel;
  currency: Currency;
  includeDaily?: boolean;
}): Plan[] => {
  const result = plans.filter(plan => {
    return (
      plan.l2 === l2 &&
      plan.pricingLevel === pricingLevel &&
      plan.currency === currency &&
      plan.pricingGeneration === ACTIVE_PRICING_GENERATION &&
      (includeDaily || plan.billingInterval !== 'day')
    );
  });
  return result;
};
