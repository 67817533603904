// not sure if there's a way to re-export the default export this way
// export * from './localization__LOCALIZATION_MODE__';

// note, eithier form of the tsc level verified import will work
// import { store } from './localization-__LOCALIZATION_MODE__';
import { store } from './localization-__LOCALIZATION_DISABLED__';

// replaces regular space characters with non-breaking space characters
export function addNbsps(string: string) {
  return string.replace(/ /g, '\u00a0');
}

export const {
  getLocale,
  setLocale,
  // setOverrideLocale,
  // clearOverrideLocale,
  onLocaleChange,
  addTranslations,
  getAvailableTranslations,
  disabled: localizationDisabled,
  translateWithDefault: __,
  translateWithDefaultFn: __f,
  scopePassthrough: __s,
  translateWithoutDefault, // used for tags
} = store;

// todo: migrate away from the default export usage
export default __;
