/// TODO: localize manually
import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { HelpCircleIcon } from '@naan/icons/help-circle-icon';
import { IconButton } from '@naan/primitives/button';
import { observer } from 'mobx-react-lite';
import { styled } from '@naan/stitches.config';
// import { AppFactory } from 'app/app-factory';

import keyboardImg from './assets/keyboard.png';
import redactionImg from './assets/redaction.png';
import speedImg from './assets/speed.png';
import translationImg from './assets/translation.png';
import vocabImg from './assets/vocab.png';
import { Image } from '@naan/primitives/image';
import { icons } from 'components/soundbites/soundbites-icons';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { getElementById } from 'components/dom-utils';
import { elementIds } from 'components/dom-utils/element-ids';

const Wrapper = styled('div', {
  padding: '0 16px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  '@medium': {
    padding: '8px 24px 24px',
  },
});

const AboutWrapper = styled('div', {
  '& h3': {
    textStyle: 'medium-heading',
    marginBottom: 12,
  },
  '& p': {
    maxWidth: 620,
  },
});

const FeaturesWrapper = styled('div', {
  '& h3': {
    textStyle: 'medium-heading',
    marginBottom: 16,
  },
  '& ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    '& li.feature': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,

      '& .feature-figure': {
        padding: '0 20px',
        background: '$colors$gray-50',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 64,

        '& .keys': {
          display: 'none',
        },
      },
      '& .feature-description b': {
        textStyle: 'body-bold',
      },
    },

    '@small': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 20,
    },
    '@medium': {
      gridTemplateColumns: 'repeat(3, 1fr)',

      '& li.feature': {
        '& .feature-figure': {
          justifyContent: 'space-between',
          '& .keys': {
            display: 'flex',
            gap: 8,
            '& .key': {
              textStyle: 'small-text-bold',
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid $colors$black-alpha-10',
              padding: '0 11px',
              borderRadius: 8,
              color: '$gray-500',
            },
          },
        },
      },
    },
  },
});

const CategoriesWrapper = styled('div', {
  '& h3': {
    textStyle: 'medium-heading',
    marginBottom: 12,
  },
  '& ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    '& li.category': {
      display: 'flex',
      gap: 12,

      '& .category-icon': {
        '& svg': {
          width: 48,
          height: 48,
        },
      },

      '& .category-description': {
        '& h4': {
          textStyle: 'body-bold',
        },
      },
    },
  },
});

const CategoryIcon = ({ name }: { name: keyof typeof icons }) => {
  const Component = icons[name].large;
  return <Component />;
};

// beware: this data is duplicated in soundbite-categories.ts
// @armando todo: refactor next time we need to touch it
const categoriesData: {
  category: keyof typeof icons;
  name: string;
  description: string;
}[] = [
  {
    category: 'accents',
    name: 'Speech & Accents',
    description:
      'Appreciating the distinctive sound and style of different speakers',
  },
  {
    category: 'vernacular',
    name: 'Vernacular & Slang',
    description: 'Colloquial usage by region or demographic',
  },
  {
    category: 'vocabulary',
    name: 'Vocabulary',
    description: 'Interesting words and expressions',
  },
  {
    category: 'grammar',
    name: 'Grammar',
    description: 'Rules for how the language fits together',
  },
  {
    category: 'patterns',
    name: 'Useful Patterns',
    description: 'Versatile language structures that boost your fluency',
  },
  {
    category: 'idioms',
    name: 'Idioms & Sayings',
    description:
      'Weird and wonderful expressions with interesting cultural roots',
  },
  {
    category: 'strategies',
    name: 'Strategies',
    description:
      'Hacks for navigating social situations (e.g. offering condolences), and how to study more effectively',
  },
  {
    category: 'culture',
    name: 'History & Culture',
    description:
      'Famous people and institutions, music and movies, celebrations and beliefs',
  },
];

export function openSoundbitesHelpDialog() {
  /// to programmatically open the dialog, we need to click the button
  /// there's no easy way to make these dialogs open programmatically without doing a big refactor
  /// And i think this approach is fine.
  getElementById(elementIds.HELP_DIALOG_BUTTON)?.click();
}

// todo: should probably rename this to "SoundbiteHelpDialog"
// this dialog used to be auto-shown the first time the soundbite player was launched,
// but now only triggered via the `?` button/key
export const SoundbiteHelpDialog = observer(
  (/*{ defaultOpen }: { defaultOpen: boolean }*/) => {
    // const model = AppFactory.soundbiteModel;

    return (
      <Dialog.PureContainer
        // defaultOpen={defaultOpen}
        // onDismiss={() => {
        //   model.dismissIntro();
        // }}
        onDismiss={() => undefined} // @armando, should `onDismiss` be a required prop here?
        trigger={
          <IconButton
            icon={<HelpCircleIcon />}
            presentation="whiteTransparent"
            noFocus
            tabIndex={-1}
            id={elementIds.HELP_DIALOG_BUTTON}
            testId="help-dialog-button"
          />
        }
        css={{
          $$maxWidth: '900px',
          '& .close-btn': {
            position: 'absolute',
            top: 12,
            right: 12,
          },
        }}
      >
        <Dialog.Body
          css={{
            padding: '0',
          }}
        >
          <Dialog.Close asChild>
            <span className="close-btn">
              <IconButton
                icon={<CloseSmallIcon />}
                size={'small'}
                presentation={'grayLight'}
                // onClick={() => model.dismissIntro()}
                testId="close-soundbite-welcome-dialog"
              />
            </span>
          </Dialog.Close>
          <Wrapper>
            <AboutWrapper>
              <h3>About Soundbites</h3>
              <p>
                A Soundbite is a listening challenge, based on an excerpt from a
                full story, designed to reveal a practical insight into an
                aspect of the language and culture.
              </p>
            </AboutWrapper>
            <FeaturesWrapper>
              <h3>Features</h3>
              <ul>
                <li className="vocab feature">
                  <div className="feature-figure">
                    <Image
                      src={vocabImg}
                      alt="Vocabulary button"
                      width={128}
                      height={32}
                    />
                    <span className="keys">
                      <span className="key">V</span>
                    </span>
                  </div>
                  <div className="feature-description">
                    Tap the <b>Vocabulary</b> button for a list of useful words
                    and phrases to help you with the listening challenge.
                  </div>
                </li>
                <li className="redaction feature">
                  <div className="feature-figure">
                    <Image
                      src={redactionImg}
                      alt="Redaction button"
                      width={139}
                      height={40}
                    />
                  </div>
                  <div className="feature-description">
                    Use this menu to <b>show or hide</b> words in the
                    transcript, according to your level.
                  </div>
                </li>
                <li className="speed feature">
                  <div className="feature-figure">
                    <Image
                      src={speedImg}
                      alt="Speed control"
                      width={64}
                      height={64}
                    />
                    <span className="keys">
                      <span className="key">
                        <PlusExtraSmallIcon />
                      </span>
                      <span className="key">
                        <MinusExtraSmallIcon />
                      </span>
                    </span>
                  </div>
                  <div className="feature-description">
                    Choose a <b>playback speed</b> between 0.6x (60%) and 1.0x
                    (native speed).
                  </div>
                </li>
                <li className="translation feature">
                  <div className="feature-figure">
                    <Image
                      src={translationImg}
                      alt="translation icon"
                      width={64}
                      height={64}
                    />
                    <span className="keys">
                      <span className="key">Tab</span>
                    </span>
                  </div>
                  <div className="feature-description">
                    After you tap "Reveal" you'll be able to access a{' '}
                    <b>full idiomatic translation</b> of the audio.
                  </div>
                </li>
                <li className="keyboard feature">
                  <div className="feature-figure">
                    <Image
                      src={keyboardImg}
                      alt="keyboard button"
                      width={49.5}
                      height={38}
                    />
                    <span className="keys">
                      <span className="key">K</span>
                    </span>
                  </div>
                  <div className="feature-description">
                    For computers with a physical <b>keyboard</b>, tap the
                    keyboard shortcuts button for a cheat sheet.
                  </div>
                </li>
              </ul>
            </FeaturesWrapper>
            <CategoriesWrapper>
              <h3>Soundbite categories</h3>
              <ul>
                {categoriesData.map(category => (
                  <li key={category.category} className="category">
                    <div className="category-icon">
                      <CategoryIcon name={category.category} />
                    </div>
                    <div className="category-description">
                      <h4>{category.name}</h4>
                      <p>{category.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </CategoriesWrapper>
          </Wrapper>
        </Dialog.Body>
      </Dialog.PureContainer>
    );
  }
);
