import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { AppFactory } from '@app/app-factory';
import { ChapterCatalogData } from '@core/models/catalog';
// import { OverflowIcon } from '@naan/icons/overflow-icon';
// import { IconButton } from '@naan/primitives/button';
// import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
// import { ChapterButtons } from '../chapter-buttons';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { Box } from '@naan/primitives/box/box';
import { Button, IconButton } from '@naan/primitives/button';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { ellipsis } from '@utils/string-utils';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { styled } from '@naan/stitches.config';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
import { ReviewChapterIcon } from './components/review-chapter-icon';

import __ from '@core/lib/localization';

const ResumeStudyConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>
        {__('Restart study from here', 'restartStudyFromHere')}
      </Dialog.Heading>
      <Dialog.Body>
        {__(
          'This will move you back to the start of this chapter for studying.',
          'thisWillMoveYouBackToTheStart'
        )}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'cancel')}
          data-test-id="okButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Restart', 'restart')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const OverflowMenu = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const onRestart = React.useCallback(() => {
      window.setTimeout(() => {
        AppFactory.dialogPresenter.present(onDismiss => (
          <ResumeStudyConfirmationDialog
            onDismiss={onDismiss}
            okAction={() => {
              chapter.restartChapter();
              onDismiss();
            }}
          />
        ));
      }, 100);
    }, [chapter]);

    return (
      <Menu
        trigger={
          <IconButton
            presentation="grayTransparent"
            icon={<OverflowIcon />}
            testId={`chapter-list-overflow-${chapter.sortingRef}`}
          />
        }
      >
        <MenuItem
          label={
            __('Restart study from here', 'restartStudyFromHere') + ellipsis
          }
          action={onRestart}
        />
      </Menu>
    );
  }
);

const Accesory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginRight: -8,
});

// special review mode
export const CurrentNotFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const navigateToStudy = useNavigateToStudy();

    const midListen = chapter.currentMillis > 0; // && isPlayerAction;
    const label = midListen
      ? __('Continue relisten', 'continueRelisten')
      : __('Relisten', 'relisten');
    // @frank, please confirm color flavors you want here.
    // do we need to define a new grayDarkProgress, or can use just use our existing grayProgress?
    const presentation = 'grayDarkProgress';
    // const presentation = midListen ? 'grayProgress' : 'grayDark';

    const onButtonClick = React.useCallback(() => {
      navigateToStudy(
        chapter,
        PlayerMode.FLUENT_LISTEN,
        chapter.currentMillis,
        false /* viaDetail */
      );
    }, [chapter, navigateToStudy]);
    return (
      <Box style={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation="current"
          stationIcon={<ReviewChapterIcon />}
          accessory={
            <Accesory>
              <OverflowMenu chapter={chapter} />
            </Accesory>
          }
        />
        <ChapterItemContents>
          <ChapterSoundbites chapter={chapter} forceOpen />
          <Box css={{ padding: '4px 0 16px' }}>
            <Button
              label={label}
              presentation={presentation}
              leftIcon={<ReplayIcon />}
              size="extraLarge"
              css={{
                $$currentProgress: midListen
                  ? `${chapter.currentPercentage}%`
                  : '0%',
              }}
              fullWidth
              onClick={onButtonClick}
            />
          </Box>
        </ChapterItemContents>
      </Box>
    );
  }
);
