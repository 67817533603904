import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {
  version,
  curEnv,
  commit,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';
import { createLogger } from '@common/log';
import App from './app';
// import { WelcomeScreen } from 'routes/auth/welcome-screen';
import { L2Choose } from 'routes/auth/welcome-screen/l2-choose';
import { L2Default } from 'routes/auth/welcome-screen/l2-default';
import { ReturnNavState } from 'components/nav/return-nav-state';

const log = createLogger('l2-router');

const buildTimestampLocal = new Date(buildTimestampIso).toString();

log.info(`${curEnv}; ${version}; ${commit}; ${buildTimestampLocal}`);

export const L2Router = () => {
  const deepLinkBaseL2Path = '/' + (ReturnNavState.getL2Cookie() || 'es');

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<L2Default />} />
        {/* <Route path="/welcome" element={<WelcomeScreen />} */}
        <Route path="/welcome" element={<L2Choose />} />
        <Route
          path="/choose"
          element={<Navigate to="/welcome" replace />}
        />{' '}
        {/* transitionary */}
        <Route path="/es/*" element={<App l2="es" />} />
        <Route path="/en/*" element={<App l2="en" />} />
        {/* redirect legacy paths */}
        <Route
          path="/app/profile/*"
          element={
            <Navigate to={`${deepLinkBaseL2Path}/app/profile`} replace />
          }
        />
        {/* @armando, what would be the best way if we wanted to preserve the full deep path in the redirect? */}
        <Route
          path="/app/*"
          element={<Navigate to={deepLinkBaseL2Path} replace />}
        />
        <Route
          path="/auth/*"
          element={<Navigate to={deepLinkBaseL2Path} replace />}
        />
        <Route
          path="/daily-soundbite"
          element={<Navigate to={deepLinkBaseL2Path} replace />}
        />
        {/* todo: handle rest of root paths, including code application */}
      </Routes>
    </BrowserRouter>
  );
};
