import * as React from 'react';
import * as Interstitial from './onboarding-interstitial';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager/story';
import { track } from '@app/track';
import { SteppedInterstitialLayout } from './component/stepped-interstitial-layout';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import __, { translateWithoutDefault } from '@core/lib/localization';

export const OnboardingCompleteInterstitial = observer(
  ({ story }: { story: Story }) => {
    React.useEffect(() => {
      track('onboarding__interstitial_shown', { stage: 'complete' });
    }, []);

    const { l2 } = AppFactory.root;
    const copy = translateWithoutDefault(`cms:onboardingCompleteBody:${l2}`);

    const handleContinue = () => {
      const { userSettings } = AppFactory.root.userManager.userData;
      userSettings.dismissTip('onboardingComplete');
      // story.progress.markCurrentChapterComplete();
    };

    return (
      <SteppedInterstitialLayout
        story={story}
        stage={3}
        title={__('Guided tour complete!', 'guidedTourCompleteBang')}
        copy={copy}
        renderButton={() => (
          <Interstitial.Button
            label={__('Continue story', 'continueStory')}
            rightIcon={<ArrowRightIcon />}
            onClick={handleContinue}
            presentation="teal"
          />
        )}
      />
    );
  }
);
