import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import { ChapterStudyButton } from './chapter-study-button';
import { observer } from 'mobx-react';
import autoAnimate from '@formkit/auto-animate';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { ChapterActionButton } from './chapter-action-button';
import classNames from 'classnames';
import { OnboardingCompleteModal } from 'routes/learn/story-detail-screen/onboarding/onboarding-complete-modal';

import __ from '@core/lib/localization';

const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  width: '100%',
  maxWidth: 240,
  height: 136,
  // margin: '20px 0 28px',
  '@medium': {
    height: 152,
    paddingBottom: 8,
    // margin: '24px 0 36px',
  },
  '& .title': {
    textStyle: 'small-heading',
  },
  '& .description': {
    textStyle: 'small-text',
    color: '$black-alpha-70',
    textAlign: 'center',
  },
  '& .duration': {
    textStyle: 'small-text',
    color: '$black-alpha-40',
  },
});

const StudyModeContent = ({ chapter }: { chapter: ChapterCatalogData }) => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Listen & Study', 'listenAmpStudy')}</div>
        <div className="description">
          {__(
            'Assisted listen with speed control, vocabulary and translation',
            'assistedListenWithSpeedControl'
          )}
        </div>
        <div className="duration">{chapter.durationInWords}</div>
      </ContentWrapper>
    </>
  );
};

const ListenModeContent = ({ chapter }: { chapter: ChapterCatalogData }) => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Natural Listen', 'naturalListen')}</div>
        <div className="description">
          {__(
            'Listen at full speed without assistance',
            'listenAtFullSpeedWithoutAssistance'
          )}
        </div>
        <div className="duration">{chapter.durationInWords}</div>
      </ContentWrapper>
    </>
  );
};

const WhoaModeContent = () => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Whoa there!', 'whoaThereBang')}</div>
        <div className="description">
          {__(
            'Complete at least one listen before marking this chapter complete',
            'completeAtLeastOneListenBeforeMarkingThisChapterComplete'
          )}
        </div>
      </ContentWrapper>
    </>
  );
};

const CompletedModeContent = () => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Ready to move on?', 'readyToMoveOn')}</div>
        <div className="description">
          {__(
            'Tap the buttons above to get back into study',
            'tapTheButtonsAboveToGetBackIntoStudy'
          )}
        </div>
      </ContentWrapper>
      <OnboardingCompleteModal />
    </>
  );
};

const ButtonsWrapper = styled('div', {
  display: 'flex',
  gap: 12,

  '&.compact': {
    gap: 7,
    opacity: 0.9,
  },
});

const modes = ['study', 'listen', 'completed'] as const;
const CardButtons = ({
  chapter,
  compact,
}: {
  chapter: ChapterCatalogData;
  compact: boolean;
}) => {
  const chapterUiModel = useChapterRowUiContext();

  const getCheckStatus = React.useCallback(
    (mode: typeof modes[number]) => {
      switch (mode) {
        case 'study':
          // return chapter.isFirstListenComplete;
          return chapter.currentPoint.studyComplete;
        case 'listen':
          // return chapter.isSecondListenComplete;
          return chapter.currentPoint.listenComplete;
        case 'completed':
          return chapter.isCompleted;
      }
    },
    [chapter]
  );

  return (
    <ButtonsWrapper className={classNames({ compact })}>
      {modes.map(mode => (
        <ChapterStudyButton
          key={mode}
          mode={mode}
          selected={chapterUiModel.currentStudyMode === mode}
          checked={getCheckStatus(mode)}
          compact={compact}
        />
      ))}
    </ButtonsWrapper>
  );
};

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  paddingTop: 40,
  borderRadius: 16,
  backgroundColor: '$$backgroundColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',
  '@medium': {
    paddingTop: 48,
  },

  '& .buttons-title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&.open': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .card-title': {
      textStyle: 'small-caps',
      color: '$black-alpha-50',
    },
  },

  variants: {
    mode: {
      study: {
        $$contextualButtonBackgroundColor: '$colors$teal-500',
        $$contextualButtonBackgroundColorHover: '$colors$teal-600',
        $$contextualButtonBackgroundColorActive: '$colors$teal-700',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$teal-200',
      },
      listen: {
        $$contextualButtonBackgroundColor: '$colors$gray-700',
        $$contextualButtonBackgroundColorHover: '$colors$gray-800',
        $$contextualButtonBackgroundColorActive: '$colors$gray-900',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$gray-100',
      },
      whoa: {
        $$contextualButtonBackgroundColor: '$colors$black-alpha-06',
        $$contextualButtonBackgroundColorHover: '$colors$black-alpha-10',
        $$contextualButtonBackgroundColorActive: '$colors$black-alpha-15',
        $$contextualButtonTextColor: '$colors$black-alpha-50',
        $$backgroundColor: '$colors$green-200',
      },
      completed: {
        $$contextualButtonBackgroundColor: '$colors$green-500',
        $$contextualButtonBackgroundColorHover: '$colors$green-600',
        $$contextualButtonBackgroundColorActive: '$colors$green-700',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$green-200',
      },
      compact: {
        $$contextualButtonBackgroundColor: '$colors$gray-700',
        $$contextualButtonBackgroundColorHover: '$colors$gray-800',
        $$contextualButtonBackgroundColorActive: '$colors$gray-900',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$gray-50',
        padding: '14px 20px ',
        gap: 16,
        // transform: 'scale(.75)',
      },
    },
    // compact: {
    //   true: {
    //     padding: '20px 16px ',
    //     $$backgroundColor: '$colors$gray-50',
    //     gap: 16,
    //   },
    // },
  },

  defaultVariant: {
    stage: 'study',
  },
});

type Mode = 'study' | 'listen' | 'completed' | 'compact' | 'whoa';

export const ChapterStudyCard = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const model = useChapterRowUiContext();
    const { currentStudyMode: mode } = model;
    const whoa = mode === 'completed' && !chapter.isFirstListenComplete;

    const parent = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    const open = model.currentStage === 'study';

    (window as any).chapter = chapter;

    let wrapperMode: Mode = !open ? 'compact' : whoa ? 'whoa' : mode;

    return (
      <Wrapper
        ref={parent}
        mode={wrapperMode}
        onClick={() => {
          model.setCurrentStage('study');
          scrollToCurrentChapter(true);
        }}
      >
        <div className={classNames('buttons-title-container', { open })}>
          {open ? null : (
            <span className="card-title">
              {__('Listen & Study', 'listenAmpStudy')}
            </span>
          )}
          <CardButtons compact={!open} chapter={chapter} />
        </div>
        {open ? (
          <>
            {mode === 'study' ? (
              <StudyModeContent chapter={chapter} />
            ) : mode === 'listen' ? (
              <ListenModeContent chapter={chapter} />
            ) : whoa ? (
              <WhoaModeContent />
            ) : mode === 'completed' ? (
              <CompletedModeContent />
            ) : null}

            {/* todo: confirm if this is ever used from the dashboard */}
            <ChapterActionButton chapter={chapter} viaDetail={false} />
          </>
        ) : null}
      </Wrapper>
    );
  }
);
