import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { styled } from '@naan/stitches.config';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { Image } from '@naan/primitives/image';

import __ from '@core/lib/localization';

import waveSrc from 'components/ui/streak-interstitial/streak-wave.svg';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const Header = styled('div', {
  $$backgroundColor: 'red',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '$$backgroundColor',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '12px 12px 0 0',
  display: 'flex',
  justifyContent: 'center',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    // height: '100%',
    background: '$$backgroundImage center/cover',
    filter: 'blur(3px)',
    transform: 'scale(1.05)',
    opacity: 0.1,
    zIndex: 1,
  },

  '& .title': {
    textStyle: 'medium-handwritten-heading',
    zIndex: 2,
    color: 'white',
    transform: 'rotate(-3deg)',
    width: 250,
    textAlign: 'center',
    fontSize: 40,
    lineHeight: '36px',
    margin: '64px 0 80px',
  },

  '& > .wave': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 1,
  },
});

const Body = styled('div', {
  padding: '24px 16px 8px',
});

export const OnboardingCompleteModal = observer(() => {
  const story = useCurrentStory();

  const dismissed = OnboardingService.instance.isDismissed(
    'onboardingCompleteModal'
  );

  if (dismissed) {
    return null;
  }

  return (
    <>
      <Dialog.Container open onDismiss={() => {}}>
        <Header
          css={{
            $$backgroundColor: story.themeColor,
            $$backgroundImage: `url(${story.listImageUrl})`,
          }}
        >
          <div className="title">
            {__(
              'Jiveworld basics tour complete!',
              'jiveworldBasicsTourComplete'
            )}
          </div>
          <Image src={waveSrc} className="wave" />
        </Header>
        <Body>
          {__(
            "Now here's the Story Screen to guide you through the final chapters — to a surprise ending...",
            'nowHeresTheStoryScreenToGuideYouThrough'
          )}
        </Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            presentation="teal"
            label="Continue story"
            onClick={() => {
              const { userSettings } = AppFactory.root.userManager.userData;
              userSettings.dismissTip('onboardingCompleteModal');
            }}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    </>
  );
});
