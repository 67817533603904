import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { StoryInterstitial } from './story-interstitial';
import { OnboardingCompleteInterstitial } from './onboarding-complete-interstitial';
import { AppFactory } from '@app/app-factory';
import { StudyInterstitial } from './study-interstitial';
import { ListenInterstitial } from './listen-interstitial';
import { BeforeAuthInterstitial } from './before-auth-interstitial';

export const OnboardingInterstitials = observer(
  ({ story }: { story: Story }) => {
    const { userManager } = AppFactory.root;
    // const onboardingService = OnboardingService.instance;

    if (story.firstChapter.completedSoundbitesCount === 0) {
      // return <SoundbiteInterstitial story={story} />;
      return <StoryInterstitial story={story} />;
    }

    if (
      !userManager.loggedInAndReady /* ||
      !onboardingService.isDismissed('storyInterstitial') */
    ) {
      return <BeforeAuthInterstitial story={story} />;
      // return <StoryInterstitial story={story} />;
    }
    if (story.progress.furthestPoint.iteration === 1) {
      return <StudyInterstitial story={story} />;
    }
    if (story.progress.furthestPoint.iteration === 2) {
      return <ListenInterstitial story={story} />;
    }
    return <OnboardingCompleteInterstitial story={story} />;
  }
);
