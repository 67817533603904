import * as React from 'react';
import {
  AdaptiveLink,
  CustomActionLink,
  FullyStyledMarkdown,
  MarkSimple,
  PlainMarkdown,
} from '@naan/primitives/text/markdown';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { Text } from '@naan/primitives/text';
import { Box } from '@naan/primitives/box';
import { VSpacer } from '@naan/primitives/spacer';

export const pathname = '/markdown';
export const navlabel = 'Markdown';
export const naanReady = true;

const exampleMd = `This is a paragraph with **bold** and _italics_ and \`code\`.

And a second paragraph.

* and also
* a list
* with many
* items
\t* a nested list
\t* with two items
\t\t* a deeply nested list
\t\t* with two items

1. and a numbered list
1. with two items
1. with three items
1. with four items
1. with five items
1. with six items
\t1. with seven items
\t1. with eight items
1. with nine items
1. with ten items
1. with eleven items
1. with twelve items

[and a link](https://example.com)`;
export const Screen = () => {
  return (
    <CenterColumnLayout>
      <Text textStyle="medium-heading">MarkSimple</Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <MarkSimple
          source={
            'This _will_ handle _italics_ without adding _extra_ elements.'
          }
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">PlainMarkdown (unstyled)</Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown source={exampleMd} />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        FullyStyledMarkdown (with predefined styles) (Don't use)
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <FullyStyledMarkdown source={exampleMd} />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        PlainMarkdown with a custom AdaptiveLink component
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown
          // source={__(
          //   "This is an [internal inline link](%{link1}), and here's [an external one](%{link2}).",
          //   'showcase.exampleAdaptiveLinkMarkdown',
          //   {
          //     link1: '/app/learn/es/collections',
          //     link2: 'http://example.com',
          //   }
          // )}
          source="This is an [internal inline link](/app/learn/es/collections), and here's [an external one](http://example.com)."
          components={{
            a: ({ node, ...props }) => <AdaptiveLink {...props} />,
          }}
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        PlainMarkdown with a custom action link component
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown
          source={'This is an ill advised [inline action](#)'}
          components={{
            a: ({ node, ...props }) => (
              <CustomActionLink
                {...props}
                action={() => {
                  // eslint-disable-next-line no-alert
                  alert('This is ill advised');
                }}
              />
            ),
          }}
        />
      </Box>
    </CenterColumnLayout>
  );
};

export const ShowcaseVolumePage = Screen;
